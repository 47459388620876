import cx from 'classnames';
import { ReactElement, ReactNode } from 'react';
import { Arrow, useHover, useLayer } from 'react-laag';
import { PlacementType } from 'react-laag/dist/PlacementType';

type Props = {
  tooltip: ReactNode;
  background?: string;
  children: ReactElement;
  placement?: PlacementType;
  shouldShow?: boolean;
  childClassName?: string;
  tooltipClassName?: string;
  tooltipTag?: 'p' | 'div';
};

export const Tooltip = ({
  tooltip,
  background,
  children,
  placement,
  shouldShow = true,
  tooltipClassName,
  childClassName,
  tooltipTag = 'p',
}: Props) => {
  const [isOver, hoverProps] = useHover();

  const { triggerProps, layerProps, renderLayer, arrowProps } = useLayer({
    isOpen: isOver && shouldShow,
    auto: true,
    placement: placement || 'bottom-center',
    triggerOffset: 6,
  });

  const TooltipTag = tooltipTag;

  return (
    <>
      {shouldShow &&
        isOver &&
        renderLayer(
          <div
            {...layerProps}
            className="z-[calc(theme(zIndex.help-center-fab)+2)]"
          >
            <div
              role="tooltip"
              className={cx(
                background ? background : 'bg-gray-800',
                'rounded px-3 py-2 shadow-md',
                tooltipClassName,
              )}
            >
              <TooltipTag className="font-inter text-xs text-white">
                {tooltip}
              </TooltipTag>
              <Arrow
                {...arrowProps}
                backgroundColor={background || '#2c3242'}
                size={5}
              />
            </div>
          </div>,
        )}
      <span {...triggerProps} {...hoverProps} className={childClassName}>
        {children}
      </span>
    </>
  );
};
