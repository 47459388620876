import { EventChannel, TakeableChannel } from 'redux-saga';
import { ForkEffect, TakeEffect } from 'redux-saga/effects';
import {
  put,
  SagaGenerator,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'typed-redux-saga';

import { RootEvent } from '../store/event';

// Narrows a union based on matching (extending) { [TagProp]: Tag }
// TY: https://spin.atomicobject.com/2021/11/10/discriminated-unions-typescript-project/
// R: https://github.com/Microsoft/TypeScript/issues/17915
// R: https://stackoverflow.com/questions/46312206/narrowing-a-return-type-from-a-generic-discriminated-union-in-typescript
type Narrow<
  Union extends { [_ in TagProp]: string },
  TagProp extends string,
  Tag extends string,
> = Union extends { [_ in TagProp]: Tag }
  ? Union
  : Tag extends Union[TagProp]
  ? Omit<Union, TagProp> & { [_ in TagProp]: Tag }
  : never;

export const putRootEvent = (event: RootEvent) => put(event);
export const takeEveryRootEvent = takeEvery as <
  T extends RootEvent['type'] | TakeableChannel<any>,
>(
  event: T | T[],
  fn: (
    event: T extends RootEvent['type'] ? Narrow<RootEvent, 'type', T> : any,
  ) => any,
) => SagaGenerator<never, ForkEffect<never>>;
export const takeLatestRootEvent = takeLatest as <
  T extends RootEvent['type'] | TakeableChannel<any>,
>(
  event: T | T[],
  fn: (
    event: T extends RootEvent['type'] ? Narrow<RootEvent, 'type', T> : any,
  ) => any,
) => SagaGenerator<never, ForkEffect<never>>;
export const takeLeadingRootEvent = takeLeading as <
  T extends RootEvent['type'] | TakeableChannel<any>,
>(
  event: T,
  fn: (
    event: T extends RootEvent['type'] ? Narrow<RootEvent, 'type', T> : any,
  ) => any,
) => SagaGenerator<never, ForkEffect<never>>;
export const takeRootEvent = take as <
  T extends RootEvent['type'] | TakeableChannel<any>,
>(
  event: T | T[],
) => SagaGenerator<Extract<RootEvent, { type: T }>, TakeEffect>;

export function* forwardChannelEvents(channel: EventChannel<RootEvent>) {
  try {
    while (true) {
      const action = yield* take(channel);
      yield* put(action);
    }
  } finally {
    channel.close();
  }
}
