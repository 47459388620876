import { useTranslation } from '@hopin-team/provider-translation';
import { useRouteError } from 'react-router-dom';

import { Button } from '../components/button/button';
import { BASE_PATH_PREFIX, Routes } from '../routes';

export function Component() {
  const { t } = useTranslation();
  const error = useRouteError() as any;

  if (
    error.status === 404 &&
    !window.location.href.startsWith(BASE_PATH_PREFIX)
  ) {
    const organiserDashboardHost = import.meta.env
      .VITE_HOPIN_ORGANISER_DASHBOARD_APP_HOST;

    window.location.href = organiserDashboardHost;
    return null;
  }

  return (
    <div className="mx-auto mt-12 max-w-xl">
      <div className="text-center">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">
          {t('error.title')}
        </h1>

        <p className="mb-8 mt-4 text-lg text-gray-500 dark:text-gray-400">
          {t('error.description')}
        </p>

        <Button
          onClick={() =>
            (window.location.href = Routes.organizationsEventsPath().path)
          }
          className="mx-auto w-1/2"
        >
          {t('error.try-again')}
        </Button>
      </div>
    </div>
  );
}

Component.displayName = 'Error';
