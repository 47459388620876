const localHosts = ['localhost', '127.0.0.1'];

export function getAPIHost() {
  return localHosts.includes(window.location.hostname)
    ? import.meta.env.VITE_HOPIN_API_HOST
    : window.location.origin;
}

export function getSSOHost() {
  return localHosts.includes(window.location.hostname)
    ? import.meta.env.VITE_HOPIN_SSO_HOST
    : window.location.origin;
}

export function getAppHost() {
  return localHosts.includes(window.location.hostname)
    ? import.meta.env.VITE_HOPIN_DASHBOARD_APP_HOST
    : window.location.origin;
}
