import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from '@hopin-team/provider-translation';

import {
  ArrowBack,
  Book,
  Megaphone,
  Play,
  RingCentral,
} from '../../../components/icons';
import { learnUrl, Routes, supportUrl, whatsNewUrl } from '../../../routes';
import { useRootSelector } from '../../../store';
import { getEvent } from '../../../store/selectors';
import { EventDetails } from './EventDetails';

export const Header = () => {
  const event = useRootSelector(getEvent);
  const { t } = useTranslation();

  return (
    <div className="bg-nav-background">
      <div className="h-layout-header sticky top-0 z-20 flex justify-between border-b border-b-gray-300">
        <a
          className="hover:bg-nav-hover group flex h-full items-center px-4"
          href={
            Routes.organizationsEventsPath(event?.organization?.externalId).path
          }
        >
          <ArrowBack className="text-gray-650 mr-2 h-2 w-2 group-hover:text-gray-900" />
          <RingCentral className="w-[140px]" />
        </a>

        <Menu as="div" className="relative">
          <Menu.Button className="hover:bg-nav-hover h-full px-4 text-gray-600">
            <div className="relative">
              <Megaphone className="h-[1.375rem] w-[1.375rem]" />
            </div>
          </Menu.Button>

          <Transition
            unmount={false}
            enter="transition ease-[cubic-bezier(0.4,0.2,0,1.2)] duration-500"
            enterFrom="transform scale-90 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-500 ease-[cubic-bezier(0.4,0.2,0,1.2)]"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-90 opacity-0"
          >
            <Menu.Items
              unmount={false}
              className="absolute left-0 z-50 mt-2 flex flex-col items-start whitespace-nowrap rounded-xl border-none bg-white p-2 font-sans text-sm font-medium text-gray-800 shadow-lg focus:outline-none"
            >
              <h2 className="text-gray-650 px-4 py-2 text-xs uppercase">
                {t('nav.learn')}
              </h2>
              <Menu.Item
                as="a"
                href={whatsNewUrl}
                className="flex w-full items-center rounded-lg p-4 text-gray-900 hover:bg-gray-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="mr-2 flex h-6 w-6 items-center justify-center text-gray-600">
                  <Megaphone className="h-4 w-4" />
                </div>
                {t('nav.whats-new')}
              </Menu.Item>
              <Menu.Item
                as="a"
                href={learnUrl}
                className="flex w-full items-center rounded-lg p-4 text-gray-900 hover:bg-gray-200"
                rel="noopener noreferrer"
              >
                <div className="mr-2 flex h-6 w-6 items-center justify-center text-gray-600">
                  <Play className="h-4 w-4" />
                </div>
                {t('nav.video-tutorials')}
              </Menu.Item>
              <Menu.Item
                as="a"
                href={supportUrl}
                className="flex w-full items-center rounded-lg p-4 text-gray-900 hover:bg-gray-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="mr-2 flex h-6 w-6 items-center justify-center text-gray-600">
                  <Book className="h-4 w-4" />
                </div>
                {t('nav.knowledge-base')}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      {event ? (
        <EventDetails />
      ) : (
        <div className="h-[174px]" data-testid="event-loading"></div>
      )}
    </div>
  );
};
