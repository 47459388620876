import { SVGProps } from 'react';

export const Website = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm9.227 10.667H17.8a18.827 18.827 0 00-1.573-6.974 9.333 9.333 0 015 6.974zM12 21.333h.6c1.213-.786 2.613-3.706 2.853-8H8.547c.186 4.32 1.64 7.24 2.853 8h.6zM8.6 10.667c.24-4.294 1.64-7.214 2.853-8H12.6c1.213.76 2.667 3.68 2.853 8H8.6zm-2.4 0c.074-2.404.608-4.771 1.573-6.974a9.333 9.333 0 00-5 6.974H6.2zm-3.427 2.666H6.2c.074 2.404.608 4.771 1.573 6.974a9.334 9.334 0 01-5-6.974zm15.027 0a18.828 18.828 0 01-1.573 6.974 9.333 9.333 0 005-6.974H17.8z"
      clipRule="evenodd"
    ></path>
  </svg>
);
