import { Switch } from '@headlessui/react';
import { useTranslation } from '@hopin-team/provider-translation';
import cx from 'classnames';

type NavigationViewToggleProps = {
  eventWizardTemplate: string;
  navigationView: string;
  onChange: (value: boolean) => void;
};

export const NavigationViewToggle = ({
  navigationView,
  eventWizardTemplate,
  onChange,
}: NavigationViewToggleProps) => {
  const { t } = useTranslation();
  const isAdvanced = navigationView === 'advanced';

  return (
    <div className="border-t border-t-gray-300 p-4 font-sans">
      <div className="pb-3">
        <h1 className="text-xs font-bold">
          {t(`event-creation-wizard.templates.${eventWizardTemplate}.title`)}
        </h1>
      </div>

      <div className="pb-2 text-xs">
        <p>{t('footer.navigation-view-toggle.description')}</p>
      </div>

      <div className="flex items-center gap-4">
        <Switch.Group>
          <Switch
            checked={isAdvanced}
            onChange={onChange}
            className={cx(
              'relative inline-flex h-6 w-10 shrink-0 cursor-pointer items-center rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75',
              {
                'bg-primary': isAdvanced,
                'bg-gray-650': !isAdvanced,
              },
            )}
          >
            <span
              aria-hidden="true"
              className={cx(
                'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out',
                {
                  'translate-x-5': isAdvanced,
                  'translate-x-1': !isAdvanced,
                },
              )}
            />
          </Switch>
          <Switch.Label className="text-sm">
            {t('footer.navigation-view-toggle.advanced-mode')}
          </Switch.Label>
        </Switch.Group>
      </div>
    </div>
  );
};
