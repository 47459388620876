import { SVGProps } from 'react';

export const InfoCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12A12 12 0 0012 0zm1.2 16.2a.6.6 0 01-.6.6h-1.2a.6.6 0 01-.6-.6v-3.6a.6.6 0 01.6-.6h1.2a.6.6 0 01.6.6v3.6zm-.6-6.6a.6.6 0 00.6-.6V7.8a.6.6 0 00-.6-.6h-1.2a.6.6 0 00-.6.6V9a.6.6 0 00.6.6h1.2z"
      clipRule="evenodd"
    />
  </svg>
);

export const InfoCircleOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0a12 12 0 0112 12c0 6.627-5.373 12-12 12S0 18.627 0 12zm2.4 0a9.6 9.6 0 1019.2 0 9.6 9.6 0 00-19.2 0zm10.8.6a.6.6 0 00-.6-.6h-1.2a.6.6 0 00-.6.6v3.6a.6.6 0 00.6.6h1.2a.6.6 0 00.6-.6v-3.6zm-.6-5.4a.6.6 0 01.6.6V9a.6.6 0 01-.6.6h-1.2a.6.6 0 01-.6-.6V7.8a.6.6 0 01.6-.6h1.2z"
      clipRule="evenodd"
    ></path>
  </svg>
);
