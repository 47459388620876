import { useTranslation } from '@hopin-team/provider-translation';

export const ErrorBoundary = () => {
  const { t } = useTranslation();
  return (
    <div className="mx-auto mt-8 flex flex-col items-center gap-4">
      <div>{t('error-boundary.header')}</div>
      <div>{t('error-boundary.body')}</div>
      <button
        className="bg-primary rounded px-4 py-2 text-white"
        onClick={() => window.location.reload()}
      >
        {t('error-boundary.retry')}
      </button>
    </div>
  );
};
