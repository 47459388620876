import { SVGProps } from 'react';

export const Settings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m20.57 14.375.8.661c.38.309.481.847.24 1.273l-1.16 2.004a1 1 0 0 1-1.15.43l-1-.36a1.996 1.996 0 0 0-1.78.19 5.474 5.474 0 0 1-.74.431c-.563.29-.956.828-1.06 1.453l-.17 1.002a1 1 0 0 1-1 .842h-2.3a1 1 0 0 1-1-.842l-.17-1.002a2.004 2.004 0 0 0-1.06-1.453 5.473 5.473 0 0 1-.74-.43 1.996 1.996 0 0 0-1.78-.191l-1 .36a.999.999 0 0 1-1.21-.43l-1.16-2.004a1.003 1.003 0 0 1 .24-1.273l.8-.661a2.006 2.006 0 0 0 .72-1.643v-.862a2.006 2.006 0 0 0-.72-1.643l-.8-.662a1.003 1.003 0 0 1-.24-1.272l1.16-2.004a.999.999 0 0 1 1.19-.431l1 .36c.59.222 1.25.151 1.78-.19.235-.162.483-.306.74-.43a2.004 2.004 0 0 0 1.02-1.453l.17-1.002a1 1 0 0 1 1-.842h2.3a1 1 0 0 1 1 .842l.17 1.002a2.004 2.004 0 0 0 1.06 1.452c.258.124.505.269.74.431.53.341 1.19.412 1.78.19l1-.36a1 1 0 0 1 1.21.43l1.16 2.005c.241.426.14.964-.24 1.272l-.8.662a2.005 2.005 0 0 0-.72 1.643v.862c-.032.63.235 1.24.72 1.643ZM9.37 12.3a3.003 3.003 0 0 0 3 3.006c.796 0 1.559-.317 2.122-.88a3.009 3.009 0 0 0 .878-2.126 3.003 3.003 0 0 0-3-3.006c-1.657 0-3 1.346-3 3.006Z"
      fill="currentColor"
    />
  </svg>
);
