import type Pusher from 'pusher-js';

import {
  type AnnouncementEmailTemplatesRecord,
  type AnnouncementEmailTemplatesResponse,
  type AnnouncementTemplatesPreviewResponse,
  type EmailActivityLogsResponse,
  type EmailTemplatesAnalyticsResponse,
  type EmailTemplatesDraftResponse,
  type EmailTemplatesPreviewResponse,
  type EmailTemplatesResponse,
  type EventOrganisersResponse,
  type TicketWithCountResponse,
} from './types';

export type Emails = {
  pageLoadCounter: number;
  emailActivityLogs: {
    data: EmailActivityLogsResponse | [];
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
  };
  emailTemplates: {
    data: EmailTemplatesResponse['templates'] | [];
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
    analytics: {
      loadingStatus: 'initial' | 'pending' | 'success' | 'error';
      data: EmailTemplatesAnalyticsResponse | [];
    };
    preview: {
      loadingStatus: 'initial' | 'pending' | 'success' | 'error';
      data: EmailTemplatesPreviewResponse | null;
    };
    draft: {
      loadingStatus: 'initial' | 'pending' | 'success' | 'error';
      data: EmailTemplatesDraftResponse | null;
    };
    sendTest: {
      loadingStatus: 'initial' | 'pending' | 'success' | 'error';
    };
    publish: {
      loadingStatus:
        | 'initial'
        | 'pending'
        | 'success'
        | 'error'
        | 'acknowledged';
    };
    reset: {
      loadingStatus:
        | 'initial'
        | 'pending'
        | 'success'
        | 'error'
        | 'acknowledged';
    };
    sendAction: {
      loadingStatus:
        | 'initial'
        | 'pending'
        | 'success'
        | 'error'
        | 'acknowledged';
    };
    systemEmails: {
      loadingStatus: 'initial' | 'pending' | 'success' | 'error';
      enabled?: boolean;
    };
    calendarInvitesInEmails: {
      loadingStatus: 'initial' | 'pending' | 'success' | 'error';
      enabled?: boolean;
    };
  };
  announcementEmailTemplates: {
    data: AnnouncementEmailTemplatesResponse | [];
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
    analytics: {
      loadingStatus: 'initial' | 'pending' | 'success' | 'error';
      data: EmailTemplatesAnalyticsResponse | [];
    };
  };
  eventOrganisers: {
    users: EventOrganisersResponse['users'] | [];
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
  };
  announcementTemplateCreate: {
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
  };
  announcementTemplateDelete: {
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
  };
  announcementTemplateDuplicate: {
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
  };
  announcementTemplateSchedule: {
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
  };
  announcementTemplateCancelSchedule: {
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
  };
  announcementTemplateSend: {
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
  };
  announcementTemplateSendTest: {
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
  };
  announcementTemplate: {
    isOpen: boolean;
    templateCopy: AnnouncementEmailTemplatesRecord | null;
    template: AnnouncementEmailTemplatesRecord | null;
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
    update: {
      loadingStatus: 'initial' | 'pending' | 'success' | 'error';
    };
  };
  announcementTemplatePreview: {
    data: AnnouncementTemplatesPreviewResponse | null;
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
  };
  ticketWithCount: {
    loadingStatus: 'initial' | 'pending' | 'success' | 'error';
    data: TicketWithCountResponse;
  };
  report: {
    pusher: Pusher | null;
  };
};

export type EmailsState = {
  emails: Emails;
};

export const initialEmailsState: EmailsState = {
  emails: {
    pageLoadCounter: 0,
    emailActivityLogs: {
      data: [],
      loadingStatus: 'initial',
    },
    emailTemplates: {
      data: [],
      loadingStatus: 'initial',
      analytics: {
        loadingStatus: 'initial',
        data: [],
      },
      preview: {
        data: null,
        loadingStatus: 'initial',
      },
      draft: {
        data: null,
        loadingStatus: 'initial',
      },
      sendTest: {
        loadingStatus: 'initial',
      },
      publish: {
        loadingStatus: 'initial',
      },
      reset: {
        loadingStatus: 'initial',
      },
      sendAction: {
        loadingStatus: 'initial',
      },
      systemEmails: {
        loadingStatus: 'initial',
      },
      calendarInvitesInEmails: {
        loadingStatus: 'initial',
      },
    },
    announcementEmailTemplates: {
      data: [],
      loadingStatus: 'initial',
      analytics: {
        loadingStatus: 'initial',
        data: [],
      },
    },
    eventOrganisers: {
      users: [],
      loadingStatus: 'initial',
    },
    announcementTemplateCreate: {
      loadingStatus: 'initial',
    },
    announcementTemplateDelete: {
      loadingStatus: 'initial',
    },
    announcementTemplateDuplicate: {
      loadingStatus: 'initial',
    },
    announcementTemplateSchedule: {
      loadingStatus: 'initial',
    },
    announcementTemplateCancelSchedule: {
      loadingStatus: 'initial',
    },
    announcementTemplateSend: {
      loadingStatus: 'initial',
    },
    announcementTemplateSendTest: {
      loadingStatus: 'initial',
    },
    ticketWithCount: {
      data: { personas: [] },
      loadingStatus: 'initial',
    },
    announcementTemplate: {
      isOpen: false,
      template: null,
      templateCopy: null,
      loadingStatus: 'initial',
      update: {
        loadingStatus: 'initial',
      },
    },
    announcementTemplatePreview: {
      data: null,
      loadingStatus: 'initial',
    },
    report: {
      pusher: null,
    },
  },
};
