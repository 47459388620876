import { Listbox, Transition } from '@headlessui/react';
import cx from 'classnames';
import { forwardRef, Fragment, ReactElement, Ref } from 'react';

import { CaretRight, Checkmark } from './icons';

type SelectProps<T = string> = {
  placeholder?: string;
  value: T;
  options: { label: string | React.ReactNode; value: T }[];
  onChange: (value: T) => void;
  name: string;
  disabled?: boolean;
  className?: string;
  hasError?: boolean;
  dataTestId?: string;
};

const SelectComponent = <T,>(
  {
    placeholder = '',
    value,
    options,
    onChange,
    name,
    disabled,
    className,
    hasError,
    dataTestId,
  }: SelectProps<T>,
  ref: Ref<HTMLDivElement>,
) => (
  <Listbox
    ref={ref}
    value={value}
    onChange={onChange}
    name={name}
    disabled={disabled}
  >
    {({ open }) => (
      <div className={cx('relative flex flex-col gap-2', className)}>
        <Listbox.Button
          data-testid={dataTestId ?? ''}
          id={name}
          className={cx(
            'focus-visible:ring-primary font-inter relative h-full w-full cursor-pointer rounded border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-sm focus:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:bg-gray-200',
            {
              ['ring-primary ring-2']: open,
              'border-red-500': hasError,
            },
          )}
        >
          <span className={cx('block truncate', { ['text-gray-600']: !value })}>
            {options.find(opt => opt.value === value)?.label || placeholder}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <CaretRight
              className="h-5 w-5 rotate-90 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="absolute top-10 z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black/5 focus:outline-none"
            data-testid={dataTestId ? `${dataTestId}-options` : ''}
          >
            {options.map((opt, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 text-sm ${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-900'
                  }`
                }
                value={opt.value}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {opt.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <Checkmark className="h-4 w-4" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>
);

export const Select = forwardRef(SelectComponent) as <T>(
  p: SelectProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
