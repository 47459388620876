import { SVGProps } from 'react';

export const ArrowBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.445 13.44A1.493 1.493 0 0 1 5 12.38v-.76c.005-.397.164-.777.445-1.06L15.832.296a1.015 1.015 0 0 1 1.434 0l1.435 1.42a.975.975 0 0 1 0 1.4L9.71 12l8.992 8.883a.995.995 0 0 1 0 1.42l-1.435 1.401a1.015 1.015 0 0 1-1.434 0L5.445 13.44z"
    />
  </svg>
);
