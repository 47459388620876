import { useSelector } from 'react-redux';

import { getAppHost } from '@/utils/host';

import { getCustomDomains } from '../selectors';

export const useEventDomain = (domainType?: 'reg' | 'app') => {
  const customDomains = useSelector(getCustomDomains);
  if (!domainType) return getAppHost();

  const part = domainType === 'reg' ? customDomains.domain : customDomains.app;
  const isAccesible = part.hostNameStatus === 'active';

  return isAccesible
    ? window.location.protocol + '//' + part.domain
    : getAppHost();
};
