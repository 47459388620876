import { TFunction } from '@hopin-team/provider-translation';

export const getLanguageOptions = (t: TFunction, supportFourChar: boolean) => [
  {
    option: supportFourChar ? 'en-US' : 'en',
    title: `${t('language-picker.english')} - English (US)`,
  },
  {
    option: supportFourChar ? 'de-DE' : 'de',
    title: `${t('language-picker.german')} - Deutsch`,
  },
  {
    option: supportFourChar ? 'es-419' : 'es',
    title: `${t('language-picker.spanish')} - Español`,
  },
  {
    option: supportFourChar ? 'ja-JP' : 'ja',
    title: `${t('language-picker.japanese')} - 日本語`,
  },
  {
    option: supportFourChar ? 'fr-FR' : 'fr',
    title: `${t('language-picker.french')} - Français`,
  },
  {
    option: supportFourChar ? 'pl-PL' : 'pl',
    title: `${t('language-picker.polish')} - Polski`,
  },
  {
    option: supportFourChar ? 'it-IT' : 'it',
    title: `${t('language-picker.italian')} - Italiano`,
  },
  {
    option: supportFourChar ? 'pt-BR' : 'pt',
    title: `${t('language-picker.portuguese-brazil')} - Português (Brazil)`,
  },
  {
    option: supportFourChar ? 'nl-NL' : 'nl',
    title: `${t('language-picker.dutch')} - Nederlands`,
  },
  {
    option: supportFourChar ? 'ko-KR' : 'ko',
    title: `${t('language-picker.korean')} - 한국어`,
  },
];
