import { SVGProps } from 'react';

export const Publish = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.005 9.884a.692.692 0 0 1-.509-.212l-.282-.282a.706.706 0 0 1 0-1.003L11.387.214a.677.677 0 0 1 .989 0l8.173 8.173a.706.706 0 0 1 0 1.003l-.282.282a.692.692 0 0 1-.509.212h-3.642v7.764c0 .39-.316.705-.705.705H8.352a.706.706 0 0 1-.705-.705V9.884H4.005zm17.758 13.41v-1.412a.706.706 0 0 0-.706-.705H2.706a.706.706 0 0 0-.706.706v1.411c0 .39.316.706.706.706h18.351c.39 0 .706-.316.706-.706z"
    />
  </svg>
);
