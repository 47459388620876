import { getLaunchDarklyClientId } from '@hopin-team/config-hopin-constants';
import { AnalyticsProvider } from '@hopin-team/provider-analytics';
import { TranslationProvider } from '@hopin-team/provider-translation';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { setHopinConstants } from './helpers/apiRouting';
import { analyticsClient, clientCallback } from './utils/analytics';
import { getAPIHost } from './utils/host';
import { initialiseRUM } from './utils/initialise-rum';

setHopinConstants({
  API_URL: getAPIHost(),
  LAUNCH_DARKLY_CLIENT_ID: getLaunchDarklyClientId(),
});

initialiseRUM();

function App({ router, store }: { router: any; store: any }) {
  return (
    <AnalyticsProvider client={analyticsClient} clientCallback={clientCallback}>
      <Provider store={store}>
        <TranslationProvider
          fallback={<div />}
          getLocale={(locale: string) => import(`./locales/${locale}.json`)}
        >
          <RouterProvider router={router} />
        </TranslationProvider>
      </Provider>
    </AnalyticsProvider>
  );
}

export default App;
