import { CustomDomainsShowPage } from '@/utils/flags';

import { getDomainPageEntry } from '../nav-config';
import { NavTab, TemplateModifier } from '../types';

type Registration = {
  'ticket-groups': object;
  'registrations-tickets': object;
  'registration-form': object;
  'registration-landing-page': object;
  details?: object;
  'magic-link-invites'?: object;
  registrants?: object;
};

type People =
  | {
      speakers: object;
      registrants: object;
      'magic-link-invites': object;
    }
  | Record<string, never>;

type Venue = {
  reception: object;
  schedule: object;
  stage: object;
  stages: object;
  session: object;
  sessions: object;
  'venue-controls-people': object;
  networking: object;
  partners: object;
  expo: object;
  sponsors: object;
  tags: object;
  surveys: object;
  'venue-controls': object;
  'app-area': object;
  speakers?: object;
};

export const getAdvancedEventWizardTemplates = ({
  reorderTabs,
  showCustomDomainsPage = CustomDomainsShowPage.CLASSIC,
}: {
  reorderTabs: boolean;
  showCustomDomainsPage?: CustomDomainsShowPage;
}): Record<TemplateModifier, NavTab> => {
  // Tabs
  const setup = {
    basics: {},
    theme: {},
    registration: {},
  };

  let registration: Registration = {
    'registrations-tickets': {},
    'ticket-groups': {},
    'registration-form': {},
    'registration-landing-page': {},
    details: {},
  };

  let venue: Venue = {
    reception: {},
    schedule: {},
    stage: {},
    stages: {},
    session: {},
    sessions: {},
    'venue-controls-people': {},
    networking: {},
    partners: {},
    expo: {},
    sponsors: {},
    tags: {},
    surveys: {},
    'venue-controls': {},
    'app-area': {},
  };

  const onsite_features = {
    'floor-plan': {},
    'check-in-areas': {},
    'kiosk-mode': {},
    badges: {},
    signatures: {},
  };

  let people: People = {
    registrants: {},
    speakers: {},
    'magic-link-invites': {},
  };

  const marketing = {
    emails: {},
    'email-attendees': {},
    'customize-emails': {},
    'sharing-and-tracking': {},
    'utm-codes': {},
  };

  const analytics = {
    engagement: {},
    'live-analytics': {},
    reports: {},
    registrations: {},
    connections: {},
    'view-polls': {},
    'expo-summary': {},
  };

  // reorder
  if (reorderTabs) {
    registration = {
      'registration-landing-page': {},
      'registration-form': {},
      'registrations-tickets': {},
      'ticket-groups': {},
      registrants: {},
      'magic-link-invites': {},
    };

    people = {};

    venue = {
      ...venue,
      speakers: {},
    };
  }

  // templates
  const advanced_pre_event = {
    overview: {},
    setup,
    registration,
    venue,
    'onsite-features': onsite_features,
    people,
    marketing,
    more: {
      recordings: {},
      'customize-text': {},
      'custom-registration-fields': {},
      'host-information': {},
      ...getDomainPageEntry(showCustomDomainsPage),
      analytics,
    },
  };

  const advanced_during_event = {
    overview: {},
    setup,
    registration,
    venue,
    'onsite-features': onsite_features,
    people,
    marketing,
    analytics,
    more: {
      recordings: {},
      'customize-text': {},
      'custom-registration-fields': {},
      'host-information': {},
      ...getDomainPageEntry(showCustomDomainsPage),
    },
  };

  const advanced_after_event = {
    overview: {},
    recordings: {},
    analytics,
    people,
    more: {
      'customize-text': {},
      'custom-registration-fields': {},
      'host-information': {},
      ...getDomainPageEntry(showCustomDomainsPage),
      setup,
      registration,
      marketing,
      venue,
      'onsite-features': onsite_features,
    },
  };

  return {
    before: advanced_pre_event,
    during: advanced_during_event,
    after: advanced_after_event,
  };
};
