import { Outlet } from 'react-router-dom';

import { AppScaffolding } from '../layouts/AppScaffolding';

type RootProps = { fullWidthLayout?: boolean };

export default function Root({ fullWidthLayout = false }: RootProps) {
  return (
    <AppScaffolding fullWidthLayout={fullWidthLayout}>
      <Outlet />
    </AppScaffolding>
  );
}
