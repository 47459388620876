import { SVGProps } from 'react';

export const ChartBarUnderline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      opacity="0.4"
      d="M21 21.75H3C2.586 21.75 2.25 21.414 2.25 21C2.25 20.586 2.586 20.25 3 20.25H21C21.414 20.25 21.75 20.586 21.75 21C21.75 21.414 21.414 21.75 21 21.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      opacity="0.4"
      d="M12.5 18H11.5C10.5 18 10 17.5 10 16.5V4.5C10 3.5 10.5 3 11.5 3H12.5C13.5 3 14 3.5 14 4.5V16.5C14 17.5 13.5 18 12.5 18Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M19.5 18H18.5C17.5 18 17 17.5 17 16.5V8.5C17 7.5 17.5 7 18.5 7H19.5C20.5 7 21 7.5 21 8.5V16.5C21 17.5 20.5 18 19.5 18Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M5.5 18H4.5C3.5 18 3 17.5 3 16.5V11.5C3 10.5 3.5 10 4.5 10H5.5C6.5 10 7 10.5 7 11.5V16.5C7 17.5 6.5 18 5.5 18Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
