export type EventLandingPage = {
  landingPage: {
    loadingStatus: 'initial' | 'pending' | 'error' | 'success';
    pageId: number | null;
    selectedLayout: 'default' | 'advanced';
    hasCustomLayoutContent: boolean;
  };
  widgets: {
    listOpen: boolean;
    editorOpen: boolean;
    editorItemId: number | null;
    list: Widget[] | null;
    listLoadingStatus: 'initial' | 'pending' | 'error' | 'success';
    saveTransaction?:
      | {
          status: 'pending';
        }
      | {
          status: 'error';
          errors: Record<string, string>;
        }
      | {
          status: 'success';
        };
    deleteTransaction?: {
      status: 'awaiting-confirmation' | 'pending';
      widgetId: number;
    };
  };
  eventDetails: {
    event: Event | null;
    loadingStatus: 'initial' | 'pending' | 'error' | 'success';
    saveTransaction: EventDetailsSaveTransaction;
  };
  eventSettings: {
    loadingStatus: 'initial' | 'pending' | 'error' | 'success';
    settings: EventSettingsResponse | null;
    saveTransaction: EventSettingsSaveTransaction;
  };
  currencies: {
    prioritized: Currency[];
    all: Currency[];
  };
};

export type ConfirmationMessageForm = {
  title: string;
  message: string;
};

export type Widget = {
  id: number;
  external_id: string;
  name: string;
  enabled: boolean;
  all_ticket_types: boolean;
  ticket_types: string[];
};

export type WidgetEditorForm = {
  name: string;
  enabled: boolean;
  all_ticket_types: boolean;
  ticket_types: string[];
};

export type EventLandingPageResponse = {
  id: number;
  external_id: string;
  content: Record<string, any>;
  enabled: boolean;
  properties: Record<string, any>;
  revision: number;
};

export type Event = {
  linkedin_community_builder_enabled: boolean;
  linkedin_company_url_enabled: boolean;
  linkedin_company_url: string | null;
  linkedin_event_id: string | null;
  post_event_enabled: boolean;
  post_event_time_end: string;
  end_time: string;
  paid_tickets_allowed: boolean;
  currency: string;
};

export type EventDetailsResponse = {
  event: Event;
};

export enum EventDetailsSaveFieldName {
  LinkedinCompanyUrlEnabled = 'linkedin_company_url_enabled',
  LinkedinCompanyUrl = 'linkedin_company_url',
  LinkedinCommunityBuilderEnabled = 'linkedin_community_builder_enabled',
  LinkedinEventID = 'linkedin_event_id',
}

const EventDetailsSaveFieldNameValues = Object.values(
  EventDetailsSaveFieldName,
);

export type EventDetailsSaveFields = {
  name: EventDetailsSaveFieldName;
  value: string | boolean;
}[];

export type EventDetailsSaveStatus =
  | {
      status: 'initial';
    }
  | {
      status: 'pending';
    }
  | {
      status: 'error';
      errors: Record<string, string>;
    }
  | {
      status: 'success';
    };

export type EventDetailsSaveTransaction = {
  [K in (typeof EventDetailsSaveFieldNameValues)[number]]: EventDetailsSaveStatus;
};

export type EventSettingsResponse = {
  registration_close_message: string;
  registration_close_time: string;
  require_sso_login: boolean;
  sso_configured: boolean;
  domain_settings_type: 'allowed' | 'blocked';
  domain_settings_enabled: boolean;
  domains: string[];
  max_tickets_per_order: number;
  confirmation_custom_title: string;
  confirmation_custom_message: string;
};

export enum EventSettingsSaveFieldName {
  RegistrationCloseMessage = 'registration_close_message',
  RegistrationCloseTime = 'registration_close_time',
  RequireSSOLogin = 'require_sso_login',
  DomainSettingsType = 'domain_settings_type',
  DomainSettingsEnabled = 'domain_settings_enabled',
  Domains = 'domains',
  MaxTicketsPerOrder = 'max_tickets_per_order',
  Currency = 'currency',
  ConfirmationCustomTitle = 'confirmation_custom_title',
  ConfirmationCustomMessage = 'confirmation_custom_message',
}

const EventSettingsSaveFieldNameValues = Object.values(
  EventSettingsSaveFieldName,
);

export type EventSettingsValueType = string | boolean | string[] | number;

export type EventSettingsSaveFields = {
  name: EventSettingsSaveFieldName;
  value: EventSettingsValueType;
}[];

export type EventSettingsSaveStatus =
  | {
      status: 'initial';
    }
  | {
      status: 'pending';
    }
  | {
      status: 'error';
      errors: Record<string, string>;
    }
  | {
      status: 'success';
    };

export type EventSettingsSaveTransaction = {
  [K in (typeof EventSettingsSaveFieldNameValues)[number]]: EventSettingsSaveStatus;
};

export enum EventSettingsSaveEndPoints {
  DomainSettings = '/domain_settings',
  ConfirmationInfo = '/confirmation_info',
}

export type Currency = {
  name: string;
  iso_code: string;
};

export type CurrenciesResponse = {
  prioritized: Currency[];
  all: Currency[];
};
