import cx from 'classnames';
import { HTMLAttributes, MouseEventHandler } from 'react';

export type ButtonStyleProps = {
  className?: string;
  disabled?: boolean;
  inline?: boolean;
  kind?: 'primary' | 'secondary' | 'danger' | 'inverse' | 'tertiary';
  pattern?: 'filled' | 'outline' | 'ghost';
  size?: 'tiny' | 'small' | 'medium';
  type?: 'button' | 'submit';
};

type ButtonProps = {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const getButtonStyles = ({
  className,
  disabled,
  kind = 'primary',
  pattern = 'filled',
  size = 'medium',
  inline,
}: ButtonStyleProps) => {
  const primary = !disabled && kind === 'primary';
  const secondary = !disabled && kind === 'secondary';
  const danger = !disabled && kind === 'danger';
  const inverse = !disabled && kind === 'inverse';
  const tertiary = !disabled && kind === 'tertiary';

  return cx(
    'font-inter font-medium flex items-center justify-center rounded text-sm transition duration-200 ease-in-out',
    {
      // Primary
      'bg-primary text-white hover:bg-blue-600':
        primary && pattern === 'filled',
      'text-primary border border-gray-200 bg-transparent hover:bg-blue-100':
        primary && pattern === 'outline',
      'text-primary border-none bg-transparent hover:bg-blue-100':
        primary && pattern === 'ghost',

      // Secondary
      'text-gray-800 hover:bg-gray-200': secondary,
      'bg-white': secondary && pattern === 'filled',
      'border border-gray-200 bg-transparent disabled:border-gray-300':
        secondary && pattern === 'outline',
      'bg-transparent': secondary && pattern === 'ghost',

      // Danger
      'bg-red-500 text-white hover:bg-red-600': danger && pattern === 'filled',
      'border border-gray-200 bg-white text-red-500 hover:bg-red-100':
        danger && pattern === 'outline',
      'bg-transparent text-red-500 hover:bg-red-100':
        danger && pattern === 'ghost',

      // Inverse
      'bg-white text-gray-800 hover:bg-gray-100':
        inverse && pattern === 'filled',
      'bg-transparent text-white hover:bg-gray-700 border border-gray-650':
        inverse && pattern === 'outline',
      'bg-transparent text-white hover:bg-gray-700':
        inverse && pattern === 'ghost',

      // Sizes
      'h-6 px-2 text-xs': size === 'tiny',
      'h-8 px-3 text-sm': size === 'small',
      'h-10 px-4 text-sm': size === 'medium',
      'w-auto': inline,
      'w-full': !inline,

      // Disabled
      'text-gray-400 cursor-not-allowed pointer-events-none': disabled,
      'bg-gray-200': disabled && pattern === 'filled',
      'bg-gray-200 border border-gray-200':
        disabled && kind !== 'inverse' && pattern === 'outline',
      'bg-transparent border border-gray-700 text-gray-650':
        disabled && kind === 'inverse' && pattern === 'outline',
      'bg-transparent border-none': disabled && pattern === 'ghost',

      // Tertiary
      'bg-orange text-white hover:bg-orange-deep':
        tertiary && pattern === 'filled',
      'text-orange-deep border border-orange-deep bg-transparent hover:bg-shell':
        tertiary && pattern === 'outline',
      'text-orange-deep border-none bg-transparent hover:bg-shell':
        tertiary && pattern === 'ghost',
    },
    className,
  );
};

export const Button = ({
  className,
  children,
  disabled,
  inline,
  onClick,
  pattern,
  kind,
  size,
  ...rest
}: ButtonProps & HTMLAttributes<HTMLButtonElement> & ButtonStyleProps) => {
  return (
    <button
      className={getButtonStyles({
        kind,
        pattern,
        size,
        inline,
        className,
        disabled,
      })}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};
