export const FLAG_ENABLE_HELP_CENTER = 'product-growth-help-center';
export const FLAG_ENABLE_GLOBAL_TAGS = 'creation-enable-global-tags';
export const FLAG_ENABLE_SURVEY_CUSTOMIZATION =
  'engagement-enable-survey-customization';
export const FLAG_ENABLE_TICKET_GROUP_DEPRECATION =
  'registrations-enable-ticket-group-deprecation';
export const FLAG_ENABLED_EVENT_DASHBOARD_PAGES =
  'dashboard-enabled-event-dashboard-react-pages';
export const FLAG_ENABLE_FOUR_CHAR_LOCALES = 'dashboard-four-character-locales';
export const FLAG_ENABLE_SIMULIVE = 'enable-simulive-creation';
export const FLAG_ENABLE_RECORDINGS_BY_DEFAULT =
  'video-sessions-enable-recordings-by-default';
export const FLAG_DISABLE_RTMP_ON_FREE_PLAN = 'stage-disable-rtmp-on-free-plan';
export const FLAG_SCHEDULES_REGISTRATION_VERSION =
  'schedules-registration-version';
export const FLAG_ENABLE_VIDEO_SESSIONS_LAYOUT_V2 =
  'video-sessions-enable-layout-v2';
export const FLAG_LINKEDIN_INTEGRATION = 'linkedin-integration';
export const FLAG_ENABLE_STUDIO_BACKSTAGE = 'studio-backstage';
export const FLAG_ENABLE_ESSENTIALS_WIZARD_STREAMLINE =
  'essentials-event-wizard-streamline';
export const FLAG_REORDER_EVENT_DASHBOARD_NAV_TABS =
  'reorder-event-dashboard-nav-tabs';
export const FLAG_ENABLE_EVENTS_UNIFY_OVERVIEW = 'events-unify-overview';
export const FLAG_ENABLE_EVENTS_UNIFY_OVERVIEW_PAID_PLAN =
  'events-unify-overview-paid-plans';
export const FLAG_ESSENTIALS_IMPROVEMENTS = 'essentials-improvements';
export const FLAG_REGISTRATION_DOMAIN_RESTRICTION =
  'registration-domain-restriction';
export const FLAG_ENABLE_CURRENCY_FIELD = 'registrations-enable-currency-field';
export const FLAG_PAGE_DEPRECATE_CLASSIC_CONFIG_PAGE =
  'page-builder-deprecate-classic-config-page';
export const FLAG_PAGE_BUILDER_EDIT_SPONSORS =
  'page-builder-edit-sponsor-tiers';
export const FLAG_FREE_TRIAL = 'events-free-trial';
export const FLAG_ANALYTICS_ATTENDEE_ENGAGEMENT_SCORE_UI =
  'analytics-attendee-engagement-score-ui';
export const FLAG_CUSTOM_DOMAINS_SHOW_PAGE = 'custom-domains-show-page';
export const FLAG_VIDEO_SESSION_STUDIO = 'video-session-studio-mvp';
export const FLAG_REGISTRATIONS_CONFIRMATION_MESSAGE =
  'registration-confirmation-message';
export const FLAG_SHOW_MY_UPLOADS = 'show-my-uploads';
export const FLAG_SIMULIVE_GA = 'simulive-ga';
export const FLAG_ONSITE_EVENT_DASHBOARD_STREAMLINE =
  'onsite-event-dashboard-streamline';
export const FLAG_CONTENT_HUB_ENABLE_RECORDING_EDITOR_V2 =
  'content-hub-enable-recording-editor-v2';
export const FLAG_CONTENT_HUB_ENABLE_AI_CLIPS = 'content-hub-enable-ai-clips';

export enum CustomDomainsShowPage {
  'CLASSIC' = 'SHOW_CLASSIC',
  'NEW' = 'SHOW_NEW',
  'BOTH' = 'SHOW_BOTH',
  'NONE' = 'SHOW_NONE',
}

export const FLAG_DEFAULTS: Record<string, boolean | string | string[]> = {
  [FLAG_ENABLED_EVENT_DASHBOARD_PAGES]: [
    'onsite_floor_plan',
    'onsite_checkin_area',
    'venue_roundtables_new',
    'venue_roundtables_edit',
    'venue_roundtables_bulk_edit_group',
    'venue_roundtables_bulk_edit_all',
    'people_speakers',
    'manage_recordings',
    'manage_backup_recordings',
    'manage_rehearsal_recordings',
    'view_recording',
    'create_cuts',
    'venue_stages',
    'venue_stages_new',
    'analytics_registrations_summary',
  ],
  [FLAG_ENABLE_FOUR_CHAR_LOCALES]: true,
  [FLAG_ENABLE_GLOBAL_TAGS]: false,
  [FLAG_ENABLE_HELP_CENTER]: false,
  [FLAG_ENABLE_RECORDINGS_BY_DEFAULT]: false,
  [FLAG_ENABLE_SIMULIVE]: false,
  [FLAG_ENABLE_SURVEY_CUSTOMIZATION]: true,
  [FLAG_ENABLE_TICKET_GROUP_DEPRECATION]: true,
  [FLAG_ENABLE_VIDEO_SESSIONS_LAYOUT_V2]: true,
  [FLAG_DISABLE_RTMP_ON_FREE_PLAN]: true,
  [FLAG_SCHEDULES_REGISTRATION_VERSION]: 'latest',
  [FLAG_ENABLE_ESSENTIALS_WIZARD_STREAMLINE]: false,
  [FLAG_ENABLE_STUDIO_BACKSTAGE]: false,
  [FLAG_ENABLE_EVENTS_UNIFY_OVERVIEW]: false,
  [FLAG_ENABLE_EVENTS_UNIFY_OVERVIEW_PAID_PLAN]: false,
  [FLAG_REORDER_EVENT_DASHBOARD_NAV_TABS]: true,
  [FLAG_ESSENTIALS_IMPROVEMENTS]: false,
  [FLAG_REGISTRATION_DOMAIN_RESTRICTION]: false,
  [FLAG_ENABLE_CURRENCY_FIELD]: false,
  [FLAG_FREE_TRIAL]: false,
  [FLAG_PAGE_DEPRECATE_CLASSIC_CONFIG_PAGE]: false,
  [FLAG_PAGE_BUILDER_EDIT_SPONSORS]: false,
  [FLAG_LINKEDIN_INTEGRATION]: true,
  [FLAG_ANALYTICS_ATTENDEE_ENGAGEMENT_SCORE_UI]: false,
  [FLAG_CUSTOM_DOMAINS_SHOW_PAGE]: CustomDomainsShowPage.CLASSIC,
  [FLAG_VIDEO_SESSION_STUDIO]: false,
  [FLAG_REGISTRATIONS_CONFIRMATION_MESSAGE]: false,
  [FLAG_SHOW_MY_UPLOADS]: false,
  [FLAG_SIMULIVE_GA]: false,
  [FLAG_ONSITE_EVENT_DASHBOARD_STREAMLINE]: false,
  [FLAG_CONTENT_HUB_ENABLE_RECORDING_EDITOR_V2]: false,
  [FLAG_CONTENT_HUB_ENABLE_AI_CLIPS]: false,
};
