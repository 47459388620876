import { initLogger, logger } from '@hopin-team/browser-logger';

const service = 'fe-event-dashboard';
const isDevelopment = !import.meta.env.PROD;
const initialiseLogger = (): void => {
  if (isDevelopment) {
    return;
  }

  initLogger({
    service,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    env: import.meta.env.VITE_DD_ENV || 'production',
  });
};
initialiseLogger();

export const appLogger = logger(service);

if (isDevelopment && import.meta.env.MODE !== 'test') {
  appLogger.info(
    `${service} logger started for env: ${import.meta.env.VITE_DD_ENV}, ` +
      `mode: ${import.meta.env.MODE}`,
  );
}
