import { SVGProps } from 'react';

export const OpenInNew = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.92 3.62A1 1 0 0 0 20 3h-5a1 1 0 1 0 0 2h2.59l-7.3 7.3a1 1 0 1 0 1.42 1.4L19 6.42V9a1 1 0 1 0 2 0V4a1 1 0 0 0-.08-.38Z"
    />
    <path
      fill="currentColor"
      d="M17 21H7c-2.54 0-4-1.46-4-4V7c0-2.54 1.46-4 4-4h4a1 1 0 1 1 0 2H7c-1.44 0-2 .56-2 2v10c0 1.44.56 2 2 2h10c1.44 0 2-.56 2-2v-4a1 1 0 1 1 2 0v4c0 2.54-1.46 4-4 4Z"
      opacity={0.4}
    />
  </svg>
);
