/**
 * Application root effect entry point.
 */
import { toast } from 'react-hot-toast';
import { createBrowserRouter, matchRoutes } from 'react-router-dom';
import { eventChannel } from 'redux-saga';
import { call, cancelled, select, take, takeLatest } from 'typed-redux-saga';

import { updateDashboardPagesCookie } from '@/utils/cookie';
import { FLAG_ENABLED_EVENT_DASHBOARD_PAGES } from '@/utils/flags';

import { DashboardInitResponse, TicketResponse } from '../domain';
import { appLogger } from '../logger';
import { AppRouteIDs, reactRouterRoutes } from '../routes';
import { getAPIHost, getAppHost, getSSOHost } from '../utils/host';
import { connectToLD } from '../utils/launchdarkly-effects';
import { connectToPusher } from '../utils/pusher-effects';
import { putRootEvent, takeLatestRootEvent } from '../utils/root-typed-effects';
import { getAuth, getEvent } from './selectors';

const routeEffects: Record<
  AppRouteIDs,
  undefined | (() => Promise<{ default: any }>)
> = {
  error: undefined,
  marketing_utm_codes: () => import('../pages/utm-codes/effect'),
  onsite_floor_plan: undefined,
  onsite_checkin_area: undefined,
  people_speakers: undefined,
  venue_stages: () => import('../pages/stages/effect'),
  venue_stages_new: () => import('../pages/new-stage/effect'),
  venue_roundtables: () => import('../pages/sessions/effect'),
  root: undefined,
  root_full_width: undefined,
  setup_theme: () => import('../pages/design/effect'),
  venue_schedules: undefined,
  venue_roundtables_bulk_edit_group: () =>
    import('../pages/bulk-edit-sessions/effect'),
  venue_roundtables_bulk_edit_all: () =>
    import('../pages/bulk-edit-sessions/effect'),
  venue_roundtables_new: () => import('../pages/new-session/effect'),
  venue_roundtables_edit: () => import('../pages/edit-session/effect'),
  manage_recordings: () => import('../pages/manage-recordings/effect'),
  manage_backup_recordings: () => import('../pages/manage-recordings/effect'),
  manage_rehearsal_recordings: () =>
    import('../pages/manage-recordings/effect'),
  view_recording: () =>
    import('../pages/manage-recordings/view-recording/effect'),
  overview: () => import('../pages/overview/effect'),
  setup: () => import('../pages/setup/effect'),
  registration_landing_page: () => import('../pages/registration-page/effect'),
  marketing: () => import('../pages/marketing/effect'),
  custom_domains: () => import('../pages/custom-domains/effect'),
  engagement_summary: () => import('../pages/engagement-page/effect'),
  emails: () => import('../pages/emails/effect'),
};

/**
 * Entry effect for application.
 */
export function* root(router: ReturnType<typeof createBrowserRouter>) {
  yield* takeLatestRootEvent('header publish button clicked', publishEvent);
  yield* takeLatestRootEvent('header delete button clicked', deleteEvent);
  yield* takeLatestRootEvent('footer logout menu item clicked', function* () {
    yield* call(redirectToSignOut);
  });
  yield* takeLatestRootEvent('navigation view set', handleNavViewSet);

  yield* takeLatestRootEvent('link clicked', function* (event) {
    const flags = yield* select(state => state.flags);

    const matches = matchRoutes(reactRouterRoutes, event.payload.link);
    const matched = matches?.[matches.length - 1];

    const shouldRouteToOldDashboard =
      matches === null ||
      (matched?.route.featureFlag && !flags[matched.route.featureFlag]) ||
      (matched?.route.restrictWithIstio &&
        !flags[FLAG_ENABLED_EVENT_DASHBOARD_PAGES].includes(matched.route.id));

    if (shouldRouteToOldDashboard) {
      // some links live on completely different domains (e.g registration)
      const host = event.payload.link.startsWith(window.location.protocol)
        ? ''
        : getAppHost();
      window.location.href = host + event.payload.link;
    } else {
      // we want the most nested
      const match = matches[matches.length - 1];
      const search = event.payload.link.split('?')[1];
      router.navigate({ pathname: match.pathname, search });
    }
    yield null;
  });

  let loaded = false;
  const routerEvents = eventChannel(emit => {
    router.subscribe(state => {
      emit(state);
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  });
  try {
    yield* takeLatestRootEvent(routerEvents, function* (state) {
      const { matches } = state;
      yield* putRootEvent({
        type: 'route changed',
        payload: {
          state,
        },
      });
      const matched = matches[matches.length - 1];
      if (!matched) return;
      // Check we have a token for each route change before running route effects
      if (matched.params.slug) {
        try {
          yield* call(checkUserAuth);
        } catch (error) {
          appLogger.warn(
            `event dashboard: no token found or fetched can't startup`,
            {
              error,
            },
          );
          router.navigate('/error');
          return;
        }
      }

      // Load event data on initial route change
      if (!loaded && matched.params.slug) {
        try {
          yield* call(getDashboardInitData, matched.params.slug);
          yield* call(connectToPusher);

          const ldEvents = yield* call(connectToLD);
          if (ldEvents) {
            yield* takeLatest(ldEvents, function* (flags) {
              updateDashboardPagesCookie(
                flags[FLAG_ENABLED_EVENT_DASHBOARD_PAGES],
              );
              yield* putRootEvent({
                type: 'root effect - flags loaded',
                payload: { flags },
              });
            });
          }
        } catch (error) {
          appLogger.warn(`event dashboard: no event slug found can't startup`, {
            error,
          });
          return;
        }

        yield* take('root effect - flags loaded');
        loaded = true;
      }

      // This is a tiny bit of pipework between react-router loaders and redux/saga.
      const maybeRouteEffectImport =
        routeEffects[matched.route.id as AppRouteIDs];
      if (loaded && maybeRouteEffectImport !== undefined) {
        const routeEffectModule = yield* call(maybeRouteEffectImport);
        yield* call(routeEffectModule.default);
      }
    });
  } catch (error) {
    appLogger.error(`event dashboard: unknown error occured`, { error });
  } finally {
    if (yield* cancelled()) {
      routerEvents.close();
    }
  }
}

function* checkUserAuth() {
  const res = yield* call(() =>
    fetch(getSSOHost() + import.meta.env.VITE_HOPIN_SSO_TOKEN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }),
  );

  if (res.status !== 200) {
    yield* putRootEvent({
      type: 'root effect - failed to get token',
      payload: {
        status: res.status,
      },
    });
    yield* call(redirectToSignIn);
  } else {
    const csrf = res.headers.get('x-csrf-token') || '';
    const { token: bearer } = yield* call(() => res.json());
    yield* putRootEvent({
      type: 'root effect - retrieved token',
      payload: {
        bearer,
        csrf,
      },
    });
  }
}

function* handleNavViewSet({
  payload: { view },
}: {
  payload: {
    view: 'simplified' | 'advanced';
  };
}) {
  const event = yield* select(getEvent);
  try {
    yield* fetchAuthenticated(
      `/api/v1/event_wizard/${event?.externalId}/dashboard_overview`,
      'PUT',
      { navigation_view: view },
    );
  } catch {
    console.error('Failed to persist nav view');
  }
}

export function* fetchAuthenticated<T>(
  route: string,
  method = 'GET',
  body?: any,
  throwErrorResponse = false,
) {
  const auth = yield* select(getAuth);
  const res = yield* call(() =>
    fetch(getAPIHost() + route, {
      method,
      credentials:
        import.meta.env.VITE_USE_LOCAL_DB === 'true' ? 'include' : undefined,
      headers: {
        Authorization: `Bearer ${auth?.bearer}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Csrf-Token': auth?.csrf || '',
      },
      body: JSON.stringify(body),
    }),
  );
  if (res.status === 401) {
    yield* putRootEvent({
      type: 'root effect - fetch authenticated 401',
    });
    yield* call(redirectToSignIn);
    return;
  }
  if (res.status === 204) {
    return null;
  }
  if (!res.ok) {
    if (throwErrorResponse) {
      const errors = yield* call(() => res.json());
      throw errors;
    }
    throw new Error(res.status.toString());
  }

  return yield* call(() => res.json() as T);
}

export function* getDashboardInitData(slug: string) {
  const response = yield* call(() =>
    fetchAuthenticated<DashboardInitResponse>(
      `/api/v1/organisers/events/${slug}/dashboard_init`,
    ),
  );

  if (response) {
    yield* putRootEvent({
      type: 'loaded dashboard init data',
      payload: response,
    });
  } else {
    appLogger.info('event dashboard: dashboard has not initiated yet');
  }
}

function* publishEvent({
  payload: { draft, eventId },
}: {
  payload: {
    draft: boolean;
    eventId: number;
  };
}) {
  try {
    yield* call(
      fetchAuthenticated,
      `/organisers/events/${eventId}/publish`,
      'POST',
    );
    const slug = yield* select(state => state.event.slug);
    yield* call(getDashboardInitData, slug);

    toast.success(
      draft ? 'nav.publish.toast-published' : 'nav.publish.toast-unpublished',
    );

    yield* putRootEvent({ type: 'header publish success' });
  } catch (e) {
    toast.error('common.generic-error');
  }
}

function* deleteEvent({
  payload: { eventId },
}: {
  payload: {
    eventId: number;
  };
}) {
  try {
    yield* call(fetchAuthenticated, `/organisers/events/${eventId}`, 'DELETE');
    window.location.href = getAppHost() + '/organizations/events';
  } catch (e) {
    toast.error('common.generic-error');
  }
}

export function redirectToSignIn() {
  const signInUrl = new URL(
    getSSOHost() + import.meta.env.VITE_HOPIN_SSO_SIGN_IN,
  );
  signInUrl.searchParams.append('redirect_url', window.location.href);
  window.location.assign(signInUrl);
}

function redirectToSignOut() {
  const signOutUrl = new URL(
    getSSOHost() + import.meta.env.VITE_HOPIN_SSO_SIGN_OUT,
  );
  window.location.assign(signOutUrl);
}

export function* fetchEventTickets() {
  const event = yield* select(getEvent);

  try {
    const response = yield* call(() =>
      fetchAuthenticated<TicketResponse[]>(
        `/api/v1/registrations_component/${event?.slug}/tickets`,
      ),
    );

    if (response) {
      yield* putRootEvent({
        type: 'event tickets fetch success',
        payload: { tickets: response },
      });
    }
  } catch {
    yield* putRootEvent({
      type: 'event tickets fetch failure',
    });
    toast.error('common.generic-error');
  }
}
