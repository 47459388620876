import { getEnvironment } from '@hopin-team/dashboard-constants';
import { Helmet } from 'react-helmet';

import type { Organization, User } from '@/domain';

const API_KEY = '4e494400-be49-40d6-602e-7b0389732ebd';

type PendoProps = {
  user: User | null;
  organization?: Organization;
};

/**
 * Pendo is a marketing solution that allows displaying release notes.
 * This tool is used throughout RingCentral and now in RingCentral Events product.
 *
 * Developers docs: https://support.pendo.io/hc/en-us/articles/360046272771
 */
export function Pendo({ user, organization }: PendoProps) {
  if (
    getEnvironment() !== 'production' ||
    user === null ||
    user?.email.startsWith('automated-tests') ||
    !organization
  ) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {`
            (function(apiKey){
                (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
                v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                    o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                    y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                    z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
            
                    // This function creates visitors and accounts in Pendo
                    pendo.initialize({
                        visitor: {
                            id: '${user.externalId}',
                        },
                        account: {
                            id: '${organization.externalId}',
                            paymentPlan: '${organization.currentPaymentPlan.name}',
                        }
                    });
            })('${API_KEY}');
        `}
      </script>
    </Helmet>
  );
}
