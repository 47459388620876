const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export function updateDashboardPagesCookie(activePages?: string[]) {
  if (!Array.isArray(activePages)) return;

  const cookieValue = encodeURIComponent(activePages.join(','));
  const expires = new Date();
  expires.setTime(expires.getTime() + ONE_DAY_IN_MS);

  document.cookie = `ev_dash_recomp=${cookieValue}; path=/; expires=${expires.toUTCString()}; SameSite=Lax; Secure`;
}
