import { TFunction } from '@hopin-team/provider-translation';
import { addDays, isSameDay } from 'date-fns';

import {
  AREA_TYPE,
  ErrorResponse,
  Recording,
  RECORDING_CATEGORY,
  RecordingGroupsFilters,
  RecordingResponse,
  TYPES_WITH_SECONDARY_LINKS,
  VideoArea,
} from '@/pages/manage-recordings/domain';
import { Routes } from '@/routes';

export const mapRecordingResponse = (
  recording: RecordingResponse,
): Recording => ({
  id: recording.id,
  title: recording.title,
  description: recording.description,
  category: recording.category,
  duration: recording.duration,
  failure_reason: recording.failure_reason,
  eventPart: {
    name: recording.event_part_name,
    type: recording.event_part_type,
    id: recording.event_part_id,
  },
  playableUrl: recording.playable_url,
  downloadUrl: recording.download_url,
  thumbnail: recording.thumbnail,
  published: recording.published,
  startedAt: recording.started_at,
  createdAt: recording.created_at,
  endedAt: recording.ended_at,
  parentRecordingId: recording.parent_recording_id || undefined,
  status: recording.status,
  statusChangedAt: recording.status_changed_at,
  simulives: recording.simulives,
});

export const getDaysBetween = (startDate: Date, endDate: Date): Date[] => {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};

export const filterVideoAreasByEventPartType = (
  videoAreas: VideoArea[],
  eventPartType?: string,
): VideoArea[] => {
  if (!videoAreas) {
    return [];
  }

  if (!eventPartType) {
    return videoAreas;
  }

  return videoAreas.filter(
    videoArea => videoArea.type === eventPartType.toLowerCase(),
  );
};

export const getCategoryFromPathname = (pathname = ''): RECORDING_CATEGORY => {
  if (pathname.match('/recordings/$') || pathname.match('/recordings$')) {
    return RECORDING_CATEGORY.MAIN;
  } else if (pathname.match('/recordings/cuts')) {
    return RECORDING_CATEGORY.CUT;
  } else if (pathname.match('/recordings/backup')) {
    return RECORDING_CATEGORY.BACKUP;
  } else if (pathname.match('/recordings/rehearsal')) {
    return RECORDING_CATEGORY.REHEARSAL;
  } else if (pathname.match('/recordings/uploads')) {
    return RECORDING_CATEGORY.UPLOAD;
  } else {
    return RECORDING_CATEGORY.MAIN;
  }
};

export const getSecondaryLinks = (
  slug: string,
  videoArea?: VideoArea,
): { rehearsalHref: string; backupHref?: string } => {
  const areaQueryParams = new URLSearchParams({ areaId: videoArea?.id || '' });

  const rehearsalHref = Routes.organisersEventRecordingsRehearsalPath(
    slug,
    areaQueryParams,
  ).as;

  let backupHref = undefined;
  if (TYPES_WITH_SECONDARY_LINKS.includes(videoArea?.type || '')) {
    backupHref = Routes.organisersEventRecordingsBackupPath(
      slug,
      areaQueryParams,
    ).as;
  }

  return { rehearsalHref, backupHref };
};

export const sortRecordings = (
  recordings: Recording[],
  sortKey: 'startedAt' | 'createdAt' = 'startedAt',
): Recording[] => {
  if (!recordings || !recordings.length) {
    return [];
  }

  const SORT_A_BEFORE_B = -1;
  const SORT_B_BEFORE_A = 1;
  const KEEP_SORTING = 0;

  return recordings.sort((a, b) => {
    if (!a[sortKey] && !b[sortKey]) {
      return KEEP_SORTING;
    } else if (!a[sortKey] && b[sortKey]) {
      return SORT_B_BEFORE_A;
    } else if (a[sortKey] && !b[sortKey]) {
      return SORT_A_BEFORE_B;
    }

    const aStartedAt = new Date(a[sortKey]);
    const bStartedAt = new Date(b[sortKey]);
    return bStartedAt.getTime() - aStartedAt.getTime();
  });
};

export const mapVideoAreasToSelectOptions = (
  videoAreas: VideoArea[],
  t: TFunction,
) =>
  videoAreas.map(videoArea => ({
    value: videoArea.id,
    label: `${
      videoArea.type === AREA_TYPE.STAGE
        ? t('manage-recordings.secondary.select-stage-label')
        : t('manage-recordings.secondary.select-session-label')
    }: ${videoArea.name}`,
  }));

export const filterRecordings = (
  recordings: Recording[],
  filters?: RecordingGroupsFilters,
) => {
  if (!filters) {
    return recordings;
  }

  return recordings
    .filter(
      recording =>
        !filters.eventPart || recording.eventPart.type === filters.eventPart,
    )
    .filter(
      recording =>
        !filters.date ||
        (recording.startedAt &&
          isSameDay(new Date(recording.startedAt), new Date(filters.date))),
    );
};

/**
 * endTime is passed in, as this varies for rehearsals vs primary+backup
 * rehearsals endTime = when event starts
 * primary+backup endTime = when event ends
 */
export const hasDatePassed = (endTime: string, hoursOffset = 0) => {
  const msOffset = 1000 * 60 * 60 * hoursOffset;
  const timeSinceEndTime = new Date(endTime).getTime() - new Date().getTime();
  return timeSinceEndTime < msOffset;
};

export const isErrorResponse = (obj: unknown): obj is ErrorResponse => {
  return Boolean(
    (obj as ErrorResponse)?.errors &&
      Array.isArray((obj as ErrorResponse)?.errors),
  );
};
