import { composeWithDevTools } from '@redux-devtools/extension';
import { Router } from '@remix-run/router';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import create from 'redux-saga';

import { reactRouterRoutes } from '../routes';
import { root } from './effect';
import { RootEvent } from './event';
import { reducer } from './reducer';
import { RootState } from './state';

export const initialiseStore = (router?: Router) => {
  // Create saga middleware
  const saga = create();

  // Create store apply saga middleware and in dev mode the redux devtools chrome extension
  const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(saga)),
  );

  // Start the application effects
  if (!router) {
    router = createBrowserRouter(reactRouterRoutes);
  }

  saga.run(root, router);

  return { store, router };
};

// App specific redux hooks for better typing
export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useRootDispatch = useDispatch as () => (event: RootEvent) => void;
