import { Footer } from './partials/Footer';
import { Header } from './partials/Header';
import { NavTree } from './partials/NavTree';

export const Nav = () => {
  return (
    <nav className="font-larsseit bg-nav-background w-layout-nav z-10 h-full shrink-0 overflow-y-clip">
      <div className="bg-nav-background flex h-screen flex-col break-words text-gray-800">
        <Header />
        <NavTree />
        <Footer />
      </div>
    </nav>
  );
};
