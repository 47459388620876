import { SVGProps } from 'react';

export const Undo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M22.0002 15.4598C22.0114 15.5992 21.9637 15.7369 21.8687 15.8396C21.7737 15.9422 21.6401 16.0003 21.5002 15.9998H20.0002C19.7445 16.0007 19.5311 15.8047 19.5102 15.5498C19.2202 12.7298 16.2002 10.4998 12.5102 10.4998C10.6168 10.4681 8.77499 11.1175 7.32023 12.3298L9.84023 14.8498C9.93789 14.9439 9.9922 15.0743 9.99023 15.2098V15.4998C9.99023 15.776 9.76638 15.9998 9.49023 15.9998H2.49023C2.21409 15.9998 1.99023 15.776 1.99023 15.4998V8.49985C1.99021 8.36549 2.04426 8.23677 2.1402 8.14271C2.23614 8.04865 2.3659 7.99716 2.50023 7.99985H2.79023C2.92582 7.99788 3.05616 8.05219 3.15023 8.14985L5.57023 10.5698C7.50419 8.87487 9.99895 7.95893 12.5702 7.99985C17.5502 7.99985 21.6702 11.2898 22.0002 15.4598Z"
      fill="currentColor"
    />
  </svg>
);
