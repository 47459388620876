import { SVGProps } from 'react';

export const Megaphone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M24 9.491a2 2 0 00-1.33-1.873.5.5 0 00-.67.471l.007 3.824a.5.5 0 00.67.47A2.001 2.001 0 0024 10.495V9.491zM20.5 3.496a1.492 1.492 0 00-.7-1.26c-.834-.53-1.469-.086-2.726.52A22.5 22.5 0 016.972 5.442a.5.5 0 00-.472.5v8.607a8.312 8.312 0 004.6 7.334.998.998 0 001.347-.45.999.999 0 00-.459-1.343 6.288 6.288 0 01-3.48-5.42c2.99.23 5.9 1.07 8.55 2.47 1.376.646 1.938 1.035 2.756.5a1.495 1.495 0 00.683-1.265l.003-12.88zM5 6.012a.5.5 0 00-.5-.5l-1.013.008A3.505 3.505 0 000 9.037l.009 2a3.5 3.5 0 003.516 3.484H4.5a.5.5 0 00.5-.5V6.011z"
    />
  </svg>
);
