import { SVGProps } from 'react';

export const Book = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.6 0H20a1.2 1.2 0 011.2 1.2v22.2a.6.6 0 01-.6.6h-15A3.6 3.6 0 012 20.4V3.6A3.6 3.6 0 015.6 0zm.6 4.8a.6.6 0 00-.6.6v1.2a.6.6 0 00.6.6H17a.6.6 0 00.6-.6V5.4a.6.6 0 00-.6-.6H6.2zm-.6 5.4a.6.6 0 01.6-.6h6a.6.6 0 01.6.6v1.2a.6.6 0 01-.6.6h-6a.6.6 0 01-.6-.6v-1.2zm0 9a1.2 1.2 0 100 2.4h13.2v-2.4H5.6z"
      clipRule="evenodd"
    />
  </svg>
);
