import type { Navigator } from '@slicesjs/core';
import { Mount, ReactiveMap, useHostSlice } from '@slicesjs/react-core';
import { useMemo } from 'react';

import { Spinner } from '../icons';
import { loadWithWindow } from './loadWithWindow';

export const LATEST = 'latest';
const SCOPED_MAP = new Map();
const HELP_CENTER_SLICE_NAME =
  '@hopin-team/hopin-web-slices-help-center-organizer';

export interface MountSliceProps {
  packageName: string;
  version?: string;
  reactiveMap: ReactiveMap<any, any>;
  basename?: string;
  pathname?: string;
  navigator?: Navigator;
  urlOverride?: string;
}

export const MountSlice = ({
  packageName,
  version = LATEST,
  reactiveMap,
  basename,
  pathname,
  navigator,
  urlOverride,
}: MountSliceProps) => {
  const url = useMemo(
    () =>
      urlOverride
        ? urlOverride
        : {
            version,
          },
    [urlOverride, version],
  );

  const { mount, error } = useHostSlice({
    packageName,
    url,
    UNSAFE_customScriptInitializer: loadWithWindow,
  });

  if (mount) {
    return (
      <Mount
        mount={mount}
        scopedMap={SCOPED_MAP}
        sliceName={packageName}
        sliceVersion={version}
        reactiveMap={reactiveMap}
        basename={basename}
        pathname={pathname}
        navigator={navigator}
      />
    );
  }

  if (error) {
    throw error;
  }

  // Hide the loading indicator for the help center slice
  if (packageName === HELP_CENTER_SLICE_NAME) return null;

  return <Spinner size="large" center className="mt-4" />;
};
