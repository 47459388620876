import { useEffect } from 'react';

import { useRootSelector } from '../store';
import { getEvent, getFlags, getUser } from '../store/selectors';

export const useSetupZendesk = () => {
  const flags = useRootSelector(getFlags);
  const event = useRootSelector(getEvent);
  const user = useRootSelector(getUser);

  useEffect(() => {
    if (event && user && flags) {
      window.zE?.('webWidget', 'updateSettings', {
        webWidget: {
          chat: {
            departments: {
              select: 'hopin live support',
            },
            suppress: false,
          },
        },
      });

      window.zE?.('webWidget', 'prefill', {
        email: {
          value: user.email,
          readOnly: true,
        },
      });
    }
  }, [event, user, flags]);
};
