import { SVGProps } from 'react';

export const Alert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12A12 12 0 0012 0zm1.2 16.2a.6.6 0 01-.6.6h-1.2a.6.6 0 01-.6-.6V15a.6.6 0 01.6-.6h1.2a.6.6 0 01.6.6v1.2zm-.564-4.2a.3.3 0 00.3-.264l.48-3.864a.6.6 0 00-.6-.672h-1.632a.6.6 0 00-.6.672l.48 3.864a.3.3 0 00.3.264h1.272z"
      clipRule="evenodd"
    ></path>
  </svg>
);
