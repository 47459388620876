import { ReactNode } from 'react';

import { CustomDomainsShowPage } from '@/utils/flags';

import {
  ChartBarUnderline,
  Coupon,
  Envelope3D,
  GridSquare,
  MoreHorizontal,
  Onsite,
  PenLine,
  Recordings,
  UsersGroup,
  Venue,
} from '../../components/icons';
import { getLiteEventWizardTemplates } from './template-states/lite-constants';
import { getOnsiteEventWizardTemplates } from './template-states/onsite-constants';
import { getAdvancedEventWizardTemplates } from './template-states/pro-advanced-constants';
import {
  getSimpleEventWizardTemplatesWithNoSponsors,
  getSimpleEventWizardTemplatesWithSponsors,
} from './template-states/pro-simple-constants';
import { EventWizardTemplate, NavTab, TemplateModifier } from './types';

export const iconMap: Record<string, ReactNode> = {
  advanced: MoreHorizontal,
  more: MoreHorizontal,
  analytics: ChartBarUnderline,
  marketing: Envelope3D,
  overview: GridSquare,
  people: UsersGroup,
  registrations: Coupon,
  registration: Coupon,
  setup: PenLine,
  basics: PenLine,
  venue: Venue,
  content: Venue,
  onsite: Onsite,
  'onsite-features': Onsite,
  recordings: Recordings,
};

export const paidPlanFeatures = ['registrants', 'speakers', 'emails'];

export const getNavTreeByEventWizardTemplate = ({
  template,
  modifier,
  reorderTabs,
  showSponsors,
  showCustomDomainsPage,
}: {
  reorderTabs: boolean;
  template: EventWizardTemplate | 'default' | string;
  modifier: TemplateModifier;
  showSponsors?: boolean;
  showCustomDomainsPage: CustomDomainsShowPage;
}) => {
  const advancedTemplates = getAdvancedEventWizardTemplates({
    reorderTabs,
    showCustomDomainsPage,
  });

  const onsiteTemplates = getOnsiteEventWizardTemplates({
    reorderTabs,
    showCustomDomainsPage,
  });

  const simpleEventTemplatesWithSponsors =
    getSimpleEventWizardTemplatesWithSponsors({
      reorderTabs,
    });

  const simpleEventTemplatesWithNoSponsors =
    getSimpleEventWizardTemplatesWithNoSponsors({
      reorderTabs,
    });

  const liteTemplates = getLiteEventWizardTemplates({
    reorderTabs,
    showSponsors,
    showCustomDomainsPage,
  });

  const navTreeByEventWizardTemplates: Record<
    EventWizardTemplate | 'default',
    {
      before: NavTab;
      during: NavTab;
      after: NavTab;
    }
  > = {
    default: {
      before: advancedTemplates['before'],
      during: advancedTemplates['during'],
      after: advancedTemplates['after'],
    },

    onsite: {
      before: onsiteTemplates['before'],
      during: onsiteTemplates['during'],
      after: onsiteTemplates['after'],
    },

    webinar: {
      before: simpleEventTemplatesWithSponsors['before'],
      during: simpleEventTemplatesWithSponsors['during'],
      after: simpleEventTemplatesWithSponsors['after'],
    },

    meetup: {
      before: simpleEventTemplatesWithNoSponsors['before'],
      during: simpleEventTemplatesWithNoSponsors['during'],
      after: simpleEventTemplatesWithNoSponsors['after'],
    },

    all_hands: {
      before: simpleEventTemplatesWithNoSponsors['before'],
      during: simpleEventTemplatesWithNoSponsors['during'],
      after: simpleEventTemplatesWithNoSponsors['after'],
    },

    workshop: {
      before: simpleEventTemplatesWithNoSponsors['before'],
      during: simpleEventTemplatesWithNoSponsors['during'],
      after: simpleEventTemplatesWithNoSponsors['after'],
    },

    lite_meetup: {
      before: liteTemplates['before'],
      during: liteTemplates['during'],
      after: liteTemplates['after'],
    },

    lite_workshop: {
      before: liteTemplates['before'],
      during: liteTemplates['during'],
      after: liteTemplates['after'],
    },

    lite_webcast: {
      before: liteTemplates['before'],
      during: liteTemplates['during'],
      after: liteTemplates['after'],
    },
  };

  if (template in navTreeByEventWizardTemplates) {
    return navTreeByEventWizardTemplates[
      template as EventWizardTemplate | 'default'
    ][modifier];
  }

  return navTreeByEventWizardTemplates.default[modifier];
};

const templatesWithSimplifiedNav = {
  webinar: true,
  meetup: true,
  all_hands: true,
  workshop: true,
  onsite: false,
  lite_meetup: false,
  lite_workshop: false,
  lite_webcast: false,
};

export const eventHasSimplifiedNav = (
  eventWizardTemplate: EventWizardTemplate | 'default' | string,
): boolean => {
  if (eventWizardTemplate in templatesWithSimplifiedNav) {
    return templatesWithSimplifiedNav[
      eventWizardTemplate as EventWizardTemplate
    ];
  }
  return false;
};
