import { useTranslation } from '@hopin-team/provider-translation';
import cx from 'classnames';
import {
  forwardRef,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  type PropsWithChildren,
  type PropsWithoutRef,
  RefAttributes,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox } from '@/components/checkbox/checkbox';
import { ExclamationCircleIcon, InfoCircle } from '@/components/icons';
import { Pill } from '@/components/Pill';
import { Select } from '@/components/Select';
import { Tooltip } from '@/components/tooltip/tooltip';

import { IntegrationFormValues } from './types';

type EnabledIntegrationsProps = {
  /**
   * Allows customisation of the appearance of an app listing, things like input, label, dropdown, checkbox;
   * AppUI must expose the following componentes: Card, Label, Input, Select, Toggle, ErrorMessage
   */
  AppUI?: EnabledIntegrationUIType;
};

export const EnabledIntegrations = ({
  AppUI = defaultAppUI,
}: EnabledIntegrationsProps) => {
  const { t } = useTranslation();

  const { control, register, getValues, setValue, watch, formState } =
    useFormContext<IntegrationFormValues>();

  const enabledIntegrations = getValues('enabledIntegrations');
  const validarEmbedType = watch(
    'enabledIntegrations.validar_integration_attributes.embed_type',
  );

  const validarEmbedOptions = [
    { label: t('integrations.validar.event-hub'), value: 'eventhub' },
    { label: t('integrations.validar.leaderboard'), value: 'leaderboard' },
    { label: t('integrations.validar.vcapture'), value: 'vcapture' },
  ];

  const errors = formState.errors.enabledIntegrations;

  if (!enabledIntegrations) return null;

  return (
    <>
      {enabledIntegrations?.twitter_integration_attributes && (
        <AppUI.Card title={t('integrations.twitter.name')}>
          <AppUI.Label
            title={t('integrations.twitter.twitter-id')}
            tooltip={t('integrations.twitter.twitter-id-help-text')}
          >
            <AppUI.Input
              hasErrors={!!errors?.twitter_integration_attributes?.app_id}
              {...register(
                'enabledIntegrations.twitter_integration_attributes.app_id',
              )}
            />
            {!!errors?.twitter_integration_attributes?.app_id &&
              !!errors?.twitter_integration_attributes?.app_id.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.twitter_integration_attributes.app_id.message,
                  )}
                />
              )}
          </AppUI.Label>
        </AppUI.Card>
      )}

      {enabledIntegrations?.interprefy_integration_attributes && (
        <AppUI.Card title={t('integrations.interprefy.name')}>
          <AppUI.Label title={t('integrations.interprefy.interprefy-url')}>
            <AppUI.Input
              placeholder={t('integrations.interprefy.placeholder')}
              hasErrors={!!errors?.interprefy_integration_attributes?.app_id}
              {...register(
                'enabledIntegrations.interprefy_integration_attributes.app_id',
              )}
            />
            {!!errors?.interprefy_integration_attributes?.app_id &&
              !!errors?.interprefy_integration_attributes?.app_id.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.interprefy_integration_attributes.app_id.message,
                  )}
                />
              )}
          </AppUI.Label>
        </AppUI.Card>
      )}

      {enabledIntegrations?.drift_integration_attributes && (
        <AppUI.Card title={t('integrations.drift.name')}>
          <AppUI.Label title={t('integrations.drift.drift-app-id')}>
            <AppUI.Input
              placeholder={t('integrations.drift.placeholder')}
              hasErrors={!!errors?.drift_integration_attributes?.app_id}
              {...register(
                'enabledIntegrations.drift_integration_attributes.app_id',
              )}
            />
            {!!errors?.drift_integration_attributes?.app_id &&
              !!errors?.drift_integration_attributes?.app_id.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.drift_integration_attributes.app_id.message,
                  )}
                />
              )}
          </AppUI.Label>
        </AppUI.Card>
      )}

      {enabledIntegrations?.slido_integration_attributes && (
        <AppUI.Card title={t('integrations.slido.name')}>
          <AppUI.Label title={t('integrations.slido.slido-tab-name')}>
            <AppUI.Input
              placeholder={t('integrations.slido.placeholder')}
              hasErrors={!!errors?.slido_integration_attributes?.name}
              {...register(
                'enabledIntegrations.slido_integration_attributes.name',
              )}
            />

            {!!errors?.slido_integration_attributes?.name &&
              !!errors?.slido_integration_attributes?.name.message && (
                <AppUI.ErrorMessage
                  message={t(errors.slido_integration_attributes.name.message)}
                />
              )}
          </AppUI.Label>

          <AppUI.Label title={t('integrations.slido.slido-url')}>
            <AppUI.Input
              placeholder={t('integrations.slido.url-placeholder')}
              hasErrors={!!errors?.slido_integration_attributes?.app_id}
              {...register(
                'enabledIntegrations.slido_integration_attributes.app_id',
              )}
            />
            {!!errors?.slido_integration_attributes?.app_id &&
              !!errors?.slido_integration_attributes?.app_id.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.slido_integration_attributes.app_id.message,
                  )}
                />
              )}
          </AppUI.Label>
        </AppUI.Card>
      )}

      {enabledIntegrations?.sidepanel_embed_integration_attributes && (
        <AppUI.Card title={t('integrations.sidepanel-embed.name')}>
          <AppUI.Label
            title={t('integrations.sidepanel-embed.sidepanel-embed-name')}
          >
            <AppUI.Input
              placeholder={t('integrations.sidepanel-embed.placeholder')}
              hasErrors={!!errors?.sidepanel_embed_integration_attributes?.name}
              {...register(
                'enabledIntegrations.sidepanel_embed_integration_attributes.name',
              )}
            />

            {!!errors?.sidepanel_embed_integration_attributes?.name &&
              !!errors?.sidepanel_embed_integration_attributes?.name
                .message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.sidepanel_embed_integration_attributes.name.message,
                  )}
                />
              )}
          </AppUI.Label>

          <AppUI.Label
            title={t('integrations.sidepanel-embed.sidepanel-embed-url')}
          >
            <AppUI.Input
              placeholder={t('integrations.sidepanel-embed.url-placeholder')}
              hasErrors={!!errors?.sidepanel_embed_integration_attributes?.url}
              {...register(
                'enabledIntegrations.sidepanel_embed_integration_attributes.url',
              )}
            />
            {!!errors?.sidepanel_embed_integration_attributes?.url &&
              !!errors?.sidepanel_embed_integration_attributes?.url.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.sidepanel_embed_integration_attributes.url.message,
                  )}
                />
              )}
          </AppUI.Label>
        </AppUI.Card>
      )}

      {enabledIntegrations?.typeform_integration_attributes && (
        <AppUI.Card title={t('integrations.typeform.name')}>
          <AppUI.Label title={t('integrations.typeform.typeform-tab-name')}>
            <AppUI.Input
              placeholder={t('integrations.typeform.placeholder')}
              hasErrors={!!errors?.typeform_integration_attributes?.name}
              {...register(
                'enabledIntegrations.typeform_integration_attributes.name',
              )}
            />

            {!!errors?.typeform_integration_attributes?.name &&
              !!errors?.typeform_integration_attributes?.name.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.typeform_integration_attributes.name.message,
                  )}
                />
              )}
          </AppUI.Label>

          <AppUI.Label title={t('integrations.typeform.typeform-url')}>
            <AppUI.Input
              placeholder={t('integrations.typeform.url-placeholder')}
              hasErrors={!!errors?.typeform_integration_attributes?.url}
              {...register(
                'enabledIntegrations.typeform_integration_attributes.url',
              )}
            />
            {!!errors?.typeform_integration_attributes?.url &&
              !!errors?.typeform_integration_attributes?.url.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.typeform_integration_attributes.url.message,
                  )}
                />
              )}
          </AppUI.Label>
        </AppUI.Card>
      )}

      {enabledIntegrations?.go_fund_me_integration_attributes && (
        <AppUI.Card title={t('integrations.go-fund-me.name')}>
          <AppUI.Label title={t('integrations.go-fund-me.go-fund-me-tab-name')}>
            <AppUI.Input
              placeholder={t('integrations.go-fund-me.placeholder')}
              hasErrors={!!errors?.go_fund_me_integration_attributes?.name}
              {...register(
                'enabledIntegrations.go_fund_me_integration_attributes.name',
              )}
            />

            {!!errors?.go_fund_me_integration_attributes?.name &&
              !!errors?.go_fund_me_integration_attributes?.name.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.go_fund_me_integration_attributes.name.message,
                  )}
                />
              )}
          </AppUI.Label>

          <AppUI.Label title={t('integrations.go-fund-me.go-fund-me-url')}>
            <AppUI.Input
              placeholder={t('integrations.go-fund-me.url-placeholder')}
              hasErrors={!!errors?.go_fund_me_integration_attributes?.url}
              {...register(
                'enabledIntegrations.go_fund_me_integration_attributes.url',
              )}
            />
            {!!errors?.go_fund_me_integration_attributes?.url &&
              !!errors?.go_fund_me_integration_attributes?.url.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.go_fund_me_integration_attributes.url.message,
                  )}
                />
              )}
          </AppUI.Label>
        </AppUI.Card>
      )}

      {enabledIntegrations?.validar_integration_attributes && (
        <AppUI.Card title={t('integrations.validar.name')}>
          <AppUI.Label title={t('integrations.validar.validar-tab-name')}>
            <AppUI.Input
              placeholder={t('integrations.validar.placeholder')}
              hasErrors={!!errors?.validar_integration_attributes?.name}
              {...register(
                'enabledIntegrations.validar_integration_attributes.name',
              )}
            />

            {!!errors?.validar_integration_attributes?.name &&
              !!errors?.validar_integration_attributes?.name.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.validar_integration_attributes.name.message,
                  )}
                />
              )}
          </AppUI.Label>

          <AppUI.Label title={t('integrations.validar.embed-type')}>
            <AppUI.Select
              {...register(
                'enabledIntegrations.validar_integration_attributes.embed_type',
              )}
              placeholder={t('integrations.validar.embed-placeholder')}
              value={validarEmbedType}
              options={validarEmbedOptions}
              onChange={value =>
                setValue(
                  'enabledIntegrations.validar_integration_attributes.embed_type',
                  value,
                  {
                    shouldDirty: true,
                    shouldValidate: true,
                  },
                )
              }
              hasError={!!errors?.validar_integration_attributes?.embed_type}
              className="my-2"
            />
            {!!errors?.validar_integration_attributes?.embed_type &&
              !!errors?.validar_integration_attributes?.embed_type.message && (
                <AppUI.ErrorMessage
                  message={t(
                    errors.validar_integration_attributes.embed_type.message,
                  )}
                />
              )}
          </AppUI.Label>

          <AppUI.Label title={t('integrations.validar.validar-url')}>
            <AppUI.Input
              placeholder={t('integrations.validar.url-placeholder')}
              hasErrors={!!errors?.validar_integration_attributes?.url}
              {...register(
                'enabledIntegrations.validar_integration_attributes.url',
              )}
            />
            {!!errors?.validar_integration_attributes?.url &&
              !!errors?.validar_integration_attributes?.url.message && (
                <AppUI.ErrorMessage
                  message={t(errors.validar_integration_attributes.url.message)}
                />
              )}
          </AppUI.Label>
        </AppUI.Card>
      )}

      {enabledIntegrations?.kahoot_integration_attributes && (
        <AppUI.Card
          title={t('integrations.kahoot.name')}
          toggleSwitchLabel={t('integrations.kahoot.enabled')}
          renderToggleSwitch={() => (
            <Controller
              control={control}
              name="enabledIntegrations.kahoot_integration_attributes.active"
              render={({ field }) => (
                <>
                  <AppUI.Toggle
                    onChange={value => field.onChange(Number(value))}
                    checked={field.value === 1}
                  />
                </>
              )}
            />
          )}
          renderToggleError={() =>
            !!errors?.kahoot_integration_attributes?.enabled &&
            !!errors?.kahoot_integration_attributes?.enabled.message ? (
              <AppUI.ErrorMessage
                message={t(
                  errors.kahoot_integration_attributes.enabled.message,
                )}
              />
            ) : null
          }
        >
          <AppUI.Label title={t('integrations.kahoot.kahoot-tab-name')}>
            <AppUI.Input
              placeholder={t('integrations.kahoot.placeholder')}
              hasErrors={!!errors?.kahoot_integration_attributes?.name}
              {...register(
                'enabledIntegrations.kahoot_integration_attributes.name',
              )}
            />

            {!!errors?.kahoot_integration_attributes?.name &&
              !!errors?.kahoot_integration_attributes?.name.message && (
                <AppUI.ErrorMessage
                  message={t(errors.kahoot_integration_attributes.name.message)}
                />
              )}
          </AppUI.Label>
        </AppUI.Card>
      )}
    </>
  );
};

export type EnabledIntegrationUIType = {
  Card: (
    props: PropsWithChildren<{
      title: string;
      renderToggleSwitch?: () => JSX.Element;
      toggleSwitchLabel?: string;
      renderToggleError?: () => JSX.Element | null;
    }>,
  ) => JSX.Element;
  Label: (
    props: PropsWithChildren<{ title: string; tooltip?: string }>,
  ) => JSX.Element;
  Input: ForwardRefExoticComponent<
    PropsWithoutRef<
      {
        hasErrors?: boolean;
        className?: string;
      } & InputHTMLAttributes<HTMLInputElement>
    > &
      RefAttributes<HTMLInputElement>
  >;
  ErrorMessage: (props: { message: string }) => JSX.Element;
  Select: typeof Select;
  Toggle: typeof Checkbox | typeof Pill;
};

const defaultAppUI: EnabledIntegrationUIType = {
  Card: props => {
    const Label = defaultAppUI.Label;

    return (
      <div>
        <p className="mb-2 text-xs font-medium text-gray-800">{props.title}</p>
        <div className="mb-6 flex gap-2 align-baseline">
          {props.children}

          {props.renderToggleSwitch && (
            <div className="w-full">
              <Label title={props.toggleSwitchLabel as string}>
                {props.renderToggleSwitch()}
                {props.renderToggleError && props.renderToggleError()}
              </Label>
            </div>
          )}
        </div>
      </div>
    );
  },
  Label: props => {
    const { tooltip, title, children } = props;
    return (
      <label className="font-inter text-gray-650 w-full text-xs">
        {title}
        {tooltip && (
          <Tooltip
            tooltip={tooltip}
            childClassName="inline-block"
            tooltipClassName="w-44 text-center"
            placement="top-center"
          >
            <InfoCircle className="ml-1 h-3 w-3 text-gray-400" />
          </Tooltip>
        )}
        {children}
      </label>
    );
  },
  Input: forwardRef(function Input(props, ref) {
    const { className, hasErrors = false, ...rest } = props;
    return (
      <input
        ref={ref}
        placeholder={props.placeholder}
        className={cx(
          'font-inter placeholder:text-gray-650 mt-2 w-full rounded border border-gray-300 px-4 py-2 text-sm',
          className,
          {
            'border-red-500': hasErrors,
          },
        )}
        type="text"
        {...rest}
      />
    );
  }),
  ErrorMessage: props => (
    <div className="mt-2 flex text-xs text-red-500">
      <ExclamationCircleIcon className="min-h-[1rem] min-w-[1rem]" />
      <p className="ml-1">{props.message}</p>
    </div>
  ),
  Select: Select,
  Toggle: Checkbox,
};
