import { SVGProps } from 'react';

export const SignOut = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 19h5.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H5v14ZM15.64 6.15l5.14 5.13a.77.77 0 0 1 .22.53v.38a.75.75 0 0 1-.22.53l-5.14 5.13a.5.5 0 0 1-.71 0l-.71-.7a.5.5 0 0 1 0-.71L17.67 13H7.5a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h10.17l-3.45-3.44a.49.49 0 0 1 0-.7l.71-.71a.5.5 0 0 1 .71 0Z"
      fill="currentColor"
    />
  </svg>
);
