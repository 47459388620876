import { useTranslation } from '@hopin-team/provider-translation';
import cx from 'classnames';

type Size = 'tiny' | 'small' | 'medium' | 'large';

type Props = {
  className?: string;
  size?: Size;
  center?: boolean;
  isInverse?: boolean;
  dataTestId?: string;
};

export const Spinner = ({
  className,
  size = 'tiny',
  center,
  isInverse,
  dataTestId,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div
      aria-atomic="true"
      role="alert"
      aria-live="polite"
      data-testid={dataTestId}
    >
      <div className="sr-only">{t('common.loading')}</div>
      <div
        className={cx(
          'min-h-[1rem] min-w-[1rem] animate-spin rounded-full border border-solid',
          {
            'border-t-primary border-blue-200': !isInverse,
            'border-white/20 border-t-white': isInverse,
            'm-auto': center,
            'h-4 w-4 border-2': size === 'tiny',
            'h-6 w-6 border-2': size === 'small',
            'h-8 w-8 border-4': size === 'medium',
            'h-10 w-10 border-4': size === 'large',
          },
          className,
        )}
      ></div>
    </div>
  );
};
