import { useRootSelector } from '@/store';
import { getEvent } from '@/store/selectors';

export const useIsFreeTrial = () => {
  const event = useRootSelector(getEvent);

  if (!event) return { isFreeTrial: false, isDraft: false, isLive: false };
  const { organization, phase } = event;
  const isFreePlan = organization?.currentPaymentPlan.name === 'Free';
  const isPublishAllowed =
    organization?.currentPaymentPlan.features.publish_event;

  return {
    isFreeTrial: Boolean(isFreePlan && !isPublishAllowed),
    isDraft: phase === 'Draft',
    isLive: phase === 'Live',
  };
};
