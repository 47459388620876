import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from '@hopin-team/provider-translation';
import { useState } from 'react';

import { Settings, SignOut, Website } from '../../../components/icons';
import { Routes } from '../../../routes';
import { useRootDispatch, useRootSelector } from '../../../store';
import { getEvent, getNavigationView, getUser } from '../../../store/selectors';
import { eventHasSimplifiedNav } from '../constants';
import { LanguagePicker } from './language/LanguagePicker';
import { NavigationViewToggle } from './NavigationViewToggle';

export const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useRootDispatch();
  const event = useRootSelector(getEvent);
  const user = useRootSelector(getUser);
  const navigationView = useRootSelector(getNavigationView);
  const [languagePickerShown, setLanguagePickerShown] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <div className="relative z-20 mt-auto">
      {event && eventHasSimplifiedNav(event.eventWizardTemplate) ? (
        <NavigationViewToggle
          eventWizardTemplate={event.eventWizardTemplate}
          navigationView={navigationView}
          onChange={value =>
            dispatch({
              type: 'navigation view set',
              payload: { view: value ? 'advanced' : 'simplified' },
            })
          }
        />
      ) : null}

      <LanguagePicker
        isShowing={languagePickerShown}
        onClose={() => setLanguagePickerShown(false)}
      />

      <Menu as="div" className=" relative">
        <Menu.Button className="hover:bg-nav-hover flex h-16 w-full items-center border-t border-t-gray-300 px-6 py-4 text-gray-800 hover:text-gray-900">
          <img
            src={user?.picture}
            alt={`${user?.firstName} ${user?.lastName}`}
            className="mr-4 h-8 w-8 rounded-full object-cover"
          />
          {`${user?.firstName} ${user?.lastName}`}
        </Menu.Button>
        <Transition
          className="absolute bottom-full left-6 w-[calc(100%-3rem)]"
          enter="transition ease-[cubic-bezier(0.4,0.2,0,1.2)] duration-500"
          enterFrom="transform scale-90 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-500 ease-[cubic-bezier(0.4,0.2,0,1.2)]"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-90 opacity-0"
        >
          <Menu.Items className="z-50 flex flex-col items-start whitespace-nowrap rounded-xl border-none bg-white p-2 font-sans text-sm font-medium text-gray-800 shadow-lg focus:outline-none">
            <Menu.Item
              as="span"
              className="text-gray-650 mr-3 flex items-center px-4 py-2 text-xs"
            >
              {t('footer.account')}
            </Menu.Item>

            <Menu.Item
              as="button"
              className="mr-3 flex w-full items-center rounded-lg p-4 hover:bg-gray-200"
              onClick={() => setLanguagePickerShown(true)}
            >
              <div className="mr-2 flex h-6 w-6 items-center justify-center text-gray-600">
                <Website className="h-5 w-5" />
              </div>
              {t('footer.language')}
            </Menu.Item>

            <Menu.Item
              as="a"
              className="mr-3 flex w-full items-center rounded-lg p-4 text-gray-900 hover:bg-gray-200"
              href={Routes.profileAccountPath().path}
            >
              <div className="mr-2 flex h-6 w-6 items-center justify-center text-gray-600">
                <Settings className="h-6 w-6" />
              </div>
              {t('footer.profile')}
            </Menu.Item>

            <Menu.Item
              as="button"
              className="mr-3 flex w-full items-center rounded-lg p-4 hover:bg-gray-200"
              onClick={() =>
                void dispatch({
                  type: 'footer logout menu item clicked',
                })
              }
            >
              <div className="mr-2 flex h-6 w-6 items-center justify-center text-gray-600">
                <SignOut className="h-6 w-6" />
              </div>
              {t('footer.logout')}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
