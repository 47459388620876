import { Draft } from 'immer';

import { RootEvent } from '@/store/event';

import { type EmailsState } from './state';

export const emailTemplatesReducer = (
  draft: Draft<EmailsState>,
  event: RootEvent,
) => {
  switch (event.type) {
    case 'email templates fetch initiated': {
      draft.emails.emailTemplates.loadingStatus = 'pending';
      draft.emails.emailTemplates.data = [];

      break;
    }
    case 'email templates fetch succeeded': {
      draft.emails.emailTemplates.loadingStatus = 'success';
      draft.emails.emailTemplates.data = event.payload.templates;
      draft.emails.emailTemplates.systemEmails.enabled =
        !event.payload.settings.emails_suppressed;
      draft.emails.emailTemplates.calendarInvitesInEmails.enabled =
        event.payload.settings.attach_calendar_invite;
      break;
    }
    case 'email templates fetch failed': {
      draft.emails.emailTemplates.loadingStatus = 'error';
      draft.emails.emailTemplates.data = [];
      draft.emails.emailTemplates.systemEmails.enabled = undefined;
      draft.emails.emailTemplates.calendarInvitesInEmails.enabled = undefined;
      break;
    }
    case 'announcement templates fetch initiated': {
      draft.emails.announcementEmailTemplates.loadingStatus = 'pending';
      draft.emails.announcementEmailTemplates.data = [];
      break;
    }
    case 'announcement templates fetch succeeded': {
      draft.emails.announcementEmailTemplates.loadingStatus = 'success';
      draft.emails.announcementEmailTemplates.data = event.payload;
      break;
    }
    case 'announcement templates fetch failed': {
      draft.emails.announcementEmailTemplates.loadingStatus = 'error';
      draft.emails.announcementEmailTemplates.data = [];
      break;
    }
    case 'event organisers fetch initiated': {
      draft.emails.eventOrganisers.loadingStatus = 'pending';
      draft.emails.eventOrganisers.users = [];
      break;
    }
    case 'event organisers fetch succeeded': {
      draft.emails.eventOrganisers.loadingStatus = 'success';
      draft.emails.eventOrganisers.users = event.payload.users;
      break;
    }
    case 'event organisers fetch failed': {
      draft.emails.eventOrganisers.loadingStatus = 'error';
      draft.emails.eventOrganisers.users = [];
      break;
    }
    case 'email templates preview initiated': {
      draft.emails.emailTemplates.preview.loadingStatus = 'pending';
      draft.emails.emailTemplates.preview.data = null;
      break;
    }
    case 'email templates preview succeeded': {
      draft.emails.emailTemplates.preview.loadingStatus = 'success';
      draft.emails.emailTemplates.preview.data = event.payload;
      break;
    }
    case 'email templates preview failed': {
      draft.emails.emailTemplates.preview.loadingStatus = 'error';
      draft.emails.emailTemplates.preview.data = null;
      break;
    }
    case 'email templates draft initiated': {
      draft.emails.emailTemplates.draft.loadingStatus = 'pending';
      draft.emails.emailTemplates.draft.data = null;
      break;
    }
    case 'email templates draft failed': {
      draft.emails.emailTemplates.draft.loadingStatus = 'error';
      draft.emails.emailTemplates.draft.data = null;
      break;
    }
    case 'email templates draft succeeded': {
      draft.emails.emailTemplates.draft.loadingStatus = 'success';
      draft.emails.emailTemplates.draft.data = event.payload;

      // Update existing record
      const index = draft.emails.emailTemplates.data.findIndex(
        template => template.template_type === event.payload.template_type,
      );
      if (index !== -1) {
        draft.emails.emailTemplates.data[index] = event.payload;
      }
      break;
    }
    case 'email templates draft reset': {
      draft.emails.emailTemplates.draft.loadingStatus = 'initial';
      draft.emails.emailTemplates.draft.data = null;
      break;
    }
    case 'email templates send test initiated': {
      draft.emails.emailTemplates.sendTest.loadingStatus = 'pending';
      break;
    }
    case 'email templates send test failed': {
      draft.emails.emailTemplates.sendTest.loadingStatus = 'error';
      break;
    }
    case 'email templates send test succeeded': {
      draft.emails.emailTemplates.sendTest.loadingStatus = 'success';
      break;
    }
    case 'email templates publish initiated': {
      draft.emails.emailTemplates.publish.loadingStatus = 'pending';
      break;
    }
    case 'email templates publish failed': {
      draft.emails.emailTemplates.publish.loadingStatus = 'error';
      break;
    }
    case 'email templates publish succeeded': {
      draft.emails.emailTemplates.publish.loadingStatus = 'success';
      break;
    }
    case 'email templates publish acknowledged': {
      draft.emails.emailTemplates.publish.loadingStatus = 'acknowledged';
      break;
    }
    case 'announcement template create initiated': {
      draft.emails.announcementTemplateCreate.loadingStatus = 'pending';
      break;
    }
    case 'announcement template create failure': {
      draft.emails.announcementTemplateCreate.loadingStatus = 'error';
      break;
    }
    case 'announcement template create succeeded': {
      draft.emails.announcementTemplateCreate.loadingStatus = 'success';
      break;
    }
    case 'announcement template delete initiated': {
      draft.emails.announcementTemplateDelete.loadingStatus = 'pending';
      break;
    }
    case 'announcement template delete failure': {
      draft.emails.announcementTemplateDelete.loadingStatus = 'error';
      break;
    }
    case 'announcement template delete succeeded': {
      draft.emails.announcementTemplateDelete.loadingStatus = 'success';
      break;
    }
    case 'announcement template duplicate initiated': {
      draft.emails.announcementTemplateDuplicate.loadingStatus = 'pending';
      break;
    }
    case 'announcement template duplicate failure': {
      draft.emails.announcementTemplateDuplicate.loadingStatus = 'error';
      break;
    }
    case 'announcement template duplicate succeeded': {
      draft.emails.announcementTemplateDuplicate.loadingStatus = 'success';
      break;
    }
    case 'announcement template schedule reset': {
      draft.emails.announcementTemplateSchedule.loadingStatus = 'initial';
      break;
    }
    case 'announcement template schedule initiated': {
      draft.emails.announcementTemplateSchedule.loadingStatus = 'pending';
      break;
    }
    case 'announcement template schedule failure': {
      draft.emails.announcementTemplateSchedule.loadingStatus = 'error';
      break;
    }
    case 'announcement template schedule succeeded': {
      draft.emails.announcementTemplateSchedule.loadingStatus = 'success';
      break;
    }
    case 'announcement template cancel schedule reset': {
      draft.emails.announcementTemplateCancelSchedule.loadingStatus = 'initial';
      break;
    }
    case 'announcement template cancel schedule initiated': {
      draft.emails.announcementTemplateCancelSchedule.loadingStatus = 'pending';
      break;
    }
    case 'announcement template cancel schedule failure': {
      draft.emails.announcementTemplateCancelSchedule.loadingStatus = 'error';
      break;
    }
    case 'announcement template cancel schedule succeeded': {
      draft.emails.announcementTemplateCancelSchedule.loadingStatus = 'success';
      break;
    }
    case 'announcement template send reset': {
      draft.emails.announcementTemplateSend.loadingStatus = 'initial';
      break;
    }
    case 'announcement template send initiated': {
      draft.emails.announcementTemplateSend.loadingStatus = 'pending';
      break;
    }
    case 'announcement template send failure': {
      draft.emails.announcementTemplateSend.loadingStatus = 'error';
      break;
    }
    case 'announcement template send succeeded': {
      draft.emails.announcementTemplateSend.loadingStatus = 'success';
      break;
    }
    case 'announcement template send test initiated': {
      draft.emails.announcementTemplateSendTest.loadingStatus = 'pending';
      break;
    }
    case 'announcement template send test failure': {
      draft.emails.announcementTemplateSendTest.loadingStatus = 'error';
      break;
    }
    case 'announcement template send test succeeded': {
      draft.emails.announcementTemplateSendTest.loadingStatus = 'success';
      break;
    }
    case 'announcement template fetch initiated': {
      draft.emails.announcementTemplate.isOpen = false;
      draft.emails.announcementTemplate.loadingStatus = 'pending';
      draft.emails.announcementTemplate.template = null;
      draft.emails.announcementTemplate.templateCopy = null;
      break;
    }
    case 'announcement template fetch failure': {
      draft.emails.announcementTemplate.isOpen = false;
      draft.emails.announcementTemplate.loadingStatus = 'error';
      draft.emails.announcementTemplate.template = null;
      break;
    }
    case 'announcement template fetch succeeded': {
      draft.emails.announcementTemplate.isOpen = true;
      draft.emails.announcementTemplate.loadingStatus = 'success';
      draft.emails.announcementTemplate.template = event.payload;
      draft.emails.announcementTemplate.templateCopy = event.payload;
      break;
    }
    case 'announcement template edit close': {
      draft.emails.announcementTemplate.isOpen = false;
      draft.emails.announcementTemplate.loadingStatus = 'initial';
      draft.emails.announcementTemplate.template = null;
      draft.emails.announcementTemplate.templateCopy = null;
      break;
    }
    case 'tickets with count fetch initiated': {
      draft.emails.ticketWithCount.loadingStatus = 'pending';
      break;
    }
    case 'tickets with count fetch failure': {
      draft.emails.ticketWithCount.loadingStatus = 'error';
      break;
    }
    case 'tickets with count fetch succeeded': {
      draft.emails.ticketWithCount.loadingStatus = 'success';
      draft.emails.ticketWithCount.data = event.payload;
      break;
    }
    case 'announcement templates preview initiated': {
      draft.emails.announcementTemplatePreview.loadingStatus = 'pending';
      break;
    }
    case 'announcement templates preview failure': {
      draft.emails.announcementTemplatePreview.loadingStatus = 'error';
      break;
    }
    case 'announcement templates preview succeeded': {
      draft.emails.announcementTemplatePreview.loadingStatus = 'success';
      draft.emails.announcementTemplatePreview.data = event.payload;
      break;
    }
    case 'announcement template update initiated': {
      draft.emails.announcementTemplate.update.loadingStatus = 'pending';
      break;
    }
    case 'announcement template update failure': {
      draft.emails.announcementTemplate.update.loadingStatus = 'error';
      break;
    }
    case 'announcement template update succeeded': {
      draft.emails.announcementTemplate.update.loadingStatus = 'success';
      draft.emails.announcementTemplate.template = event.payload;
      break;
    }
    case 'email templates reset initiated': {
      draft.emails.emailTemplates.reset.loadingStatus = 'pending';
      break;
    }
    case 'email templates reset failed': {
      draft.emails.emailTemplates.reset.loadingStatus = 'error';
      break;
    }
    case 'email templates reset succeeded': {
      draft.emails.emailTemplates.reset.loadingStatus = 'success';
      break;
    }
    case 'email templates reset acknowledged': {
      draft.emails.emailTemplates.reset.loadingStatus = 'acknowledged';
      break;
    }
    case 'email templates send action initiated': {
      draft.emails.emailTemplates.sendAction.loadingStatus = 'pending';
      break;
    }
    case 'email templates send action failed': {
      draft.emails.emailTemplates.sendAction.loadingStatus = 'error';
      break;
    }
    case 'email templates send action succeeded': {
      draft.emails.emailTemplates.sendAction.loadingStatus = 'success';

      // Update existing record
      const index = draft.emails.emailTemplates.data.findIndex(
        template => template.template_type === event.payload.template_type,
      );
      if (index !== -1) {
        draft.emails.emailTemplates.data[index] = event.payload;
      }
      break;
    }
    case 'email templates send action acknowledged': {
      draft.emails.emailTemplates.sendAction.loadingStatus = 'acknowledged';
      break;
    }
    case 'email templates system emails initiated': {
      draft.emails.emailTemplates.systemEmails.loadingStatus = 'pending';
      break;
    }
    case 'email templates system emails failed': {
      draft.emails.emailTemplates.systemEmails.loadingStatus = 'error';
      break;
    }
    case 'email templates system emails succeeded': {
      draft.emails.emailTemplates.systemEmails.loadingStatus = 'success';
      draft.emails.emailTemplates.systemEmails.enabled = event.payload.enabled;
      break;
    }
    case 'email templates calendar invite initiated': {
      draft.emails.emailTemplates.calendarInvitesInEmails.loadingStatus =
        'pending';
      break;
    }
    case 'email templates calendar invite failed': {
      draft.emails.emailTemplates.calendarInvitesInEmails.loadingStatus =
        'error';
      break;
    }
    case 'email templates calendar invite succeeded': {
      draft.emails.emailTemplates.calendarInvitesInEmails.loadingStatus =
        'success';
      draft.emails.emailTemplates.calendarInvitesInEmails.enabled =
        event.payload.enabled;
      break;
    }
    case 'email templates analytics fetch initiated': {
      draft.emails.emailTemplates.analytics.loadingStatus = 'pending';
      draft.emails.emailTemplates.analytics.data = [];
      break;
    }
    case 'email templates analytics fetch failed': {
      draft.emails.emailTemplates.analytics.loadingStatus = 'error';
      draft.emails.emailTemplates.analytics.data = [];
      break;
    }
    case 'email templates analytics fetch succeeded': {
      draft.emails.emailTemplates.analytics.loadingStatus = 'success';
      draft.emails.emailTemplates.analytics.data = event.payload;
      break;
    }
    case 'email templates email activity logs fetch initiated': {
      draft.emails.emailActivityLogs.loadingStatus = 'pending';
      draft.emails.emailActivityLogs.data = [];
      break;
    }
    case 'email templates email activity logs fetch failed': {
      draft.emails.emailActivityLogs.loadingStatus = 'error';
      draft.emails.emailActivityLogs.data = [];
      break;
    }
    case 'email templates email activity logs fetch succeeded': {
      draft.emails.emailActivityLogs.loadingStatus = 'success';
      draft.emails.emailActivityLogs.data = event.payload;
      break;
    }
    case 'emails page load initiated': {
      draft.emails.pageLoadCounter += 1;
      break;
    }
    case 'emails page set report pusher': {
      draft.emails.report.pusher = event.payload.pusher;
      break;
    }
    case 'announcement email templates analytics fetch initiated': {
      draft.emails.announcementEmailTemplates.analytics.loadingStatus =
        'pending';
      draft.emails.announcementEmailTemplates.analytics.data = [];
      break;
    }
    case 'announcement email templates analytics fetch failed': {
      draft.emails.announcementEmailTemplates.analytics.loadingStatus = 'error';
      draft.emails.announcementEmailTemplates.analytics.data = [];
      break;
    }
    case 'announcement email templates analytics fetch succeeded': {
      draft.emails.announcementEmailTemplates.analytics.loadingStatus =
        'success';
      draft.emails.announcementEmailTemplates.analytics.data = event.payload;
      break;
    }
  }
};
