import { Transition } from '@headlessui/react';
import { useTranslation } from '@hopin-team/provider-translation';
import cx from 'classnames';
import { resolveValue, Toaster } from 'react-hot-toast';

import { Alert, Checkmark } from '../../../components/icons';

export const ToastContainer = () => {
  const { t } = useTranslation();

  return (
    <Toaster>
      {toast => {
        const message = resolveValue(toast.message, toast);
        const isSuccess = toast.type === 'success';
        const isError = toast.type === 'error';

        return (
          <Transition
            appear
            show={toast.visible}
            className={cx(
              'flex transform items-center justify-center rounded p-4 text-white shadow-lg',
              {
                'bg-green-400': isSuccess,
                'bg-red-300': isError,
              },
            )}
            enter="transition-all duration-150"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
          >
            {isSuccess && <Checkmark className="h-4 w-4" />}
            {isError && <Alert className="h-4 w-4" />}
            <span className="px-4">
              {typeof message === 'string' ? t(message) : message}
            </span>
          </Transition>
        );
      }}
    </Toaster>
  );
};
