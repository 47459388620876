import { CustomDomainsShowPage } from '@/utils/flags';

import { getDomainPageEntry } from '../nav-config';
import { NavTab, TemplateModifier } from '../types';

type Registration = {
  'registrations-tickets': object;
  'ticket-groups': object;
  'registration-form': object;
  'registration-landing-page': object;
  details?: object;
  'magic-link-invites'?: object;
  registrants?: object;
};

type People =
  | {
      speakers: object;
      registrants: object;
      'magic-link-invites': object;
    }
  | Record<string, never>;

type Setup = {
  basics: object;
  setup: object;
  theme: object;
  reception: object;
  schedule: object;
  speakers?: object;
  sponsors?: object;
  registration: object;
};

export const getLiteEventWizardTemplates = ({
  reorderTabs,
  showSponsors,
  showCustomDomainsPage = CustomDomainsShowPage.CLASSIC,
}: {
  reorderTabs: boolean;
  showSponsors?: boolean;
  showCustomDomainsPage?: CustomDomainsShowPage;
}): Record<TemplateModifier, NavTab> => {
  // Tabs
  let registration: Registration = {
    'registrations-tickets': {},
    'ticket-groups': {},
    'registration-form': {},
    'registration-landing-page': {},
    details: {},
  };

  let setup: Setup = {
    basics: {},
    setup: {},
    theme: {},
    reception: {},
    schedule: {},
    registration: {},
    ...(showSponsors ? { sponsors: {} } : {}),
  };

  let people: People = {
    registrants: {},
    speakers: {},
    'magic-link-invites': {},
  };

  const marketing = {
    emails: {},
    'email-attendees': {},
    'customize-emails': {},
    'sharing-and-tracking': {},
    'utm-codes': {},
  };

  const analytics = {
    'live-analytics': {},
    reports: {},
    registrations: {},
    'view-polls': {},
    surveys: {},
  };

  // Reorder
  if (reorderTabs) {
    registration = {
      'registration-landing-page': {},
      'registration-form': {},
      'registrations-tickets': {},
      'ticket-groups': {},
      registrants: {},
      'magic-link-invites': {},
    };

    people = {};

    setup = {
      ...setup,
      speakers: {},
    };
  }

  // Templates
  const liteEventConfig_pre_event = {
    overview: {},
    registration,
    setup,
    people,
    marketing,
    more: {
      recordings: {},
      'customize-text': {},
      'custom-registration-fields': {},
      'host-information': {},
      ...getDomainPageEntry(showCustomDomainsPage),
      analytics,
    },
  };

  const liteEventConfig_during_event = {
    overview: {},
    registration,
    setup,
    people,
    marketing,
    analytics,
    more: {
      recordings: {},
      'customize-text': {},
      'custom-registration-fields': {},
      'host-information': {},
    },
  };

  const liteEventConfig_after_event = {
    overview: {},
    recordings: {},
    analytics,
    people,
    more: {
      'customize-text': {},
      'custom-registration-fields': {},
      'host-information': {},
      setup,
      registration,
      marketing,
    },
  };

  return {
    before: liteEventConfig_pre_event,
    during: liteEventConfig_during_event,
    after: liteEventConfig_after_event,
  };
};
