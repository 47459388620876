import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from '@hopin-team/provider-translation';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { Button } from '@/components/button/button';
import { useIsFreeTrial } from '@/hooks/useIsFreeTrial';

import { Close, Copy, MoreHorizontal } from '../../../components/icons';
import { Routes } from '../../../routes';
import { useRootDispatch, useRootSelector } from '../../../store';
import { getEvent } from '../../../store/selectors';
import { PublishButton } from './PublishButton';

export const EventDetails = () => {
  const { slug } = useParams();
  const event = useRootSelector(getEvent);
  const { t } = useTranslation();
  const dispatch = useRootDispatch();
  const { isFreeTrial, isDraft } = useIsFreeTrial();

  if (!event) return null;
  const { timeStartLocal, validForDeletion, name } = event;
  const startTimeFormatted =
    timeStartLocal &&
    format(new Date(timeStartLocal), `EEE',' LLL d',' h:mm aa'`);

  const onDeleteEvent = () => {
    const confirm = window.confirm(t('nav.confirm-delete-event'));

    if (confirm) {
      dispatch({
        type: 'header delete button clicked',
        payload: {
          eventId: event.id,
        },
      });
    }
  };

  const onDuplicateEvent = () => {
    dispatch({
      type: 'link clicked',
      payload: {
        link: Routes.organisersDuplicationsPath(slug).as,
      },
    });
  };

  return (
    <div>
      <div className="my-4 px-6">
        <p className="mb-1 text-lg font-medium">{name}</p>
        <div className="relative z-10 mb-1 flex h-7 w-full items-center justify-between font-sans text-sm text-gray-800">
          <p>{startTimeFormatted}</p>

          <Menu as="div" className="relative">
            <Menu.Button className="p-1 text-gray-800 hover:text-gray-900">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">{t('nav.event-menu')}</span>
            </Menu.Button>
            <Transition
              enter="transition ease-[cubic-bezier(0.4,0.2,0,1.2)] duration-500"
              enterFrom="transform scale-90 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-500 ease-[cubic-bezier(0.4,0.2,0,1.2)]"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-90 opacity-0"
            >
              <Menu.Items className="absolute left-0 z-50 flex flex-col items-start whitespace-nowrap rounded-xl border-none bg-white p-2 font-sans text-sm font-medium text-gray-800 shadow-lg focus:outline-none">
                <Menu.Item
                  as="button"
                  className="mr-3 flex w-full items-center rounded-lg p-4 hover:bg-gray-200"
                  onClick={onDuplicateEvent}
                >
                  <>
                    <div className="mr-2 flex h-6 w-6 items-center justify-center text-gray-800">
                      <Copy className="h-4 w-4" />
                    </div>
                    {t('nav.duplicate-event')}
                  </>
                </Menu.Item>

                {validForDeletion && (
                  <Menu.Item
                    as="button"
                    className="mr-3 flex w-full items-center rounded-lg p-4 hover:bg-gray-200"
                    onClick={onDeleteEvent}
                  >
                    <>
                      <div className="mr-2 flex h-6 w-6 items-center justify-center text-gray-800">
                        <Close className="h-3 w-3" />
                      </div>
                      {t('nav.delete-event')}
                    </>
                  </Menu.Item>
                )}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      {!event.isLite || (event.isLite && isFreeTrial && isDraft) ? (
        <>
          <div className="mb-2 px-6">
            <PublishButton />
          </div>

          <div className="mb-2 rounded-sm px-6">
            <Button
              className="flex gap-2 whitespace-nowrap border border-gray-300"
              onClick={onDuplicateEvent}
              kind="primary"
              pattern="outline"
              size="small"
            >
              <Copy className="h-3 w-3" />
              <span>{t('nav.duplicate-event')}</span>
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};
