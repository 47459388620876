export const tzList = [
  'International Date Line West',
  'American Samoa',
  'Midway Island',
  'Hawaii',
  'Alaska',
  'Pacific Time (US & Canada)',
  'Tijuana',
  'Arizona',
  'Chihuahua',
  'Mazatlan',
  'Mountain Time (US & Canada)',
  'Central America',
  'Central Time (US & Canada)',
  'Guadalajara',
  'Mexico City',
  'Monterrey',
  'Saskatchewan',
  'Bogota',
  'Eastern Time (US & Canada)',
  'Indiana (East)',
  'Lima',
  'Quito',
  'Atlantic Time (Canada)',
  'Caracas',
  'Georgetown',
  'La Paz',
  'Puerto Rico',
  'Santiago',
  'Newfoundland',
  'Brasilia',
  'Buenos Aires',
  'Greenland',
  'Montevideo',
  'Mid-Atlantic',
  'Azores',
  'Cape Verde Is.',
  'Casablanca',
  'Dublin',
  'Edinburgh',
  'Lisbon',
  'London',
  'Monrovia',
  'UTC',
  'Amsterdam',
  'Belgrade',
  'Berlin',
  'Bern',
  'Bratislava',
  'Brussels',
  'Budapest',
  'Copenhagen',
  'Ljubljana',
  'Madrid',
  'Paris',
  'Prague',
  'Rome',
  'Sarajevo',
  'Skopje',
  'Stockholm',
  'Vienna',
  'Warsaw',
  'West Central Africa',
  'Zagreb',
  'Zurich',
  'Athens',
  'Bucharest',
  'Cairo',
  'Harare',
  'Helsinki',
  'Jerusalem',
  'Kaliningrad',
  'Kyiv',
  'Pretoria',
  'Riga',
  'Sofia',
  'Tallinn',
  'Vilnius',
  'Baghdad',
  'Istanbul',
  'Kuwait',
  'Minsk',
  'Moscow',
  'Nairobi',
  'Riyadh',
  'St. Petersburg',
  'Tehran',
  'Abu Dhabi',
  'Baku',
  'Muscat',
  'Samara',
  'Tbilisi',
  'Volgograd',
  'Yerevan',
  'Kabul',
  'Ekaterinburg',
  'Islamabad',
  'Karachi',
  'Tashkent',
  'Chennai',
  'Kolkata',
  'Mumbai',
  'New Delhi',
  'Sri Jayawardenepura',
  'Kathmandu',
  'Almaty',
  'Astana',
  'Dhaka',
  'Urumqi',
  'Rangoon',
  'Bangkok',
  'Hanoi',
  'Jakarta',
  'Krasnoyarsk',
  'Novosibirsk',
  'Beijing',
  'Chongqing',
  'Hong Kong',
  'Irkutsk',
  'Kuala Lumpur',
  'Perth',
  'Singapore',
  'Taipei',
  'Ulaanbaatar',
  'Osaka',
  'Sapporo',
  'Seoul',
  'Tokyo',
  'Yakutsk',
  'Adelaide',
  'Darwin',
  'Brisbane',
  'Canberra',
  'Guam',
  'Hobart',
  'Melbourne',
  'Port Moresby',
  'Sydney',
  'Vladivostok',
  'Magadan',
  'New Caledonia',
  'Solomon Is.',
  'Srednekolymsk',
  'Auckland',
  'Fiji',
  'Kamchatka',
  'Marshall Is.',
  'Wellington',
  'Chatham Is.',
  "Nuku'alofa",
  'Samoa',
  'Tokelau Is.',
] as const;

export const timezonesMap = {
  UTC: 'UTC',
  'International Date Line West': 'Pacific/Funafuti',
  'American Samoa': 'Pacific/Samoa',
  'Midway Island': 'Pacific/Midway',
  Hawaii: 'Pacific/Honolulu',
  Alaska: 'America/Anchorage',
  'Pacific Time (US & Canada)': 'Canada/Pacific',
  Tijuana: 'America/Tijuana',
  Arizona: 'America/Phoenix',
  Chihuahua: 'America/Chihuahua',
  Mazatlan: 'America/Mazatlan',
  'Mountain Time (US & Canada)': 'America/Denver',
  'Central America': 'America/Mexico_City',
  'Central Time (US & Canada)': 'America/Chicago',
  Guadalajara: 'America/Mexico_City',
  'Mexico City': 'America/Mexico_City',
  Monterrey: 'America/Monterrey',
  Saskatchewan: 'Canada/Saskatchewan',
  Bogota: 'America/Bogota',
  'Eastern Time (US & Canada)': 'America/New_York',
  'Indiana (East)': 'US/East-Indiana',
  Lima: 'America/Lima',
  Quito: 'America/Guayaquil',
  'Atlantic Time (Canada)': 'America/Halifax',
  Caracas: 'America/Caracas',
  Georgetown: 'America/Guyana',
  'La Paz': 'America/La_Paz',
  'Puerto Rico': 'America/Puerto_Rico',
  Santiago: 'America/Santiago',
  Newfoundland: 'Canada/Newfoundland',
  Brasilia: 'America/Sao_Paulo',
  'Buenos Aires': 'America/Argentina/Buenos_Aires',
  Greenland: 'America/Godthab',
  Montevideo: 'America/Montevideo',
  'Mid-Atlantic': 'America/Noronha',
  Azores: 'Atlantic/Azores',
  'Cape Verde Is.': 'Atlantic/Cape_Verde',
  Casablanca: 'Africa/Casablanca',
  Dublin: 'Europe/Dublin',
  Edinburgh: 'Europe/London',
  Lisbon: 'Europe/Lisbon',
  London: 'Europe/London',
  Monrovia: 'Africa/Monrovia',
  Amsterdam: 'Europe/Amsterdam',
  Belgrade: 'Europe/Belgrade',
  Berlin: 'Europe/Berlin',
  Bern: 'Europe/Zurich',
  Bratislava: 'Europe/Bratislava',
  Brussels: 'Europe/Brussels',
  Budapest: 'Europe/Budapest',
  Copenhagen: 'Europe/Copenhagen',
  Ljubljana: 'Europe/Ljubljana',
  Madrid: 'Europe/Madrid',
  Paris: 'Europe/Paris',
  Prague: 'Europe/Prague',
  Rome: 'Europe/Rome',
  Sarajevo: 'Europe/Sarajevo',
  Skopje: 'Europe/Skopje',
  Stockholm: 'Europe/Stockholm',
  Vienna: 'Europe/Vienna',
  Warsaw: 'Europe/Warsaw',
  'West Central Africa': 'Africa/Bangui',
  Zagreb: 'Europe/Zagreb',
  Zurich: 'Europe/Zurich',
  Athens: 'Europe/Athens',
  Bucharest: 'Europe/Bucharest',
  Cairo: 'Africa/Cairo',
  Harare: 'Africa/Harare',
  Helsinki: 'Europe/Helsinki',
  Jerusalem: 'Asia/Jerusalem',
  Kaliningrad: 'Europe/Kaliningrad',
  Kyiv: 'Europe/Kiev',
  Pretoria: 'Africa/Johannesburg',
  Riga: 'Europe/Riga',
  Sofia: 'Europe/Sofia',
  Tallinn: 'Europe/Tallinn',
  Vilnius: 'Europe/Vilnius',
  Baghdad: 'Asia/Baghdad',
  Istanbul: 'Asia/Istanbul',
  Kuwait: 'Asia/Kuwait',
  Minsk: 'Europe/Minsk',
  Moscow: 'Europe/Moscow',
  Nairobi: 'Africa/Nairobi',
  Riyadh: 'Asia/Riyadh',
  'St. Petersburg': 'Europe/Moscow',
  Tehran: 'Asia/Tehran',
  'Abu Dhabi': 'Asia/Dubai',
  Baku: 'Asia/Baku',
  Muscat: 'Asia/Muscat',
  Samara: 'Europe/Samara',
  Tbilisi: 'Asia/Tbilisi',
  Volgograd: 'Europe/Volgograd',
  Yerevan: 'Asia/Yerevan',
  Kabul: 'Asia/Kabul',
  Ekaterinburg: 'Asia/Yekaterinburg',
  Islamabad: 'Asia/Karachi',
  Karachi: 'Asia/Karachi',
  Tashkent: 'Asia/Tashkent',
  Chennai: 'Asia/Kolkata',
  Kolkata: 'Asia/Kolkata',
  Mumbai: 'Asia/Kolkata',
  'New Delhi': 'Asia/Kolkata',
  'Sri Jayawardenepura': 'Asia/Colombo',
  Kathmandu: 'Asia/Kathmandu',
  Almaty: 'Asia/Almaty',
  Astana: 'Asia/Almaty',
  Dhaka: 'Asia/Dhaka',
  Urumqi: 'Asia/Urumqi',
  Rangoon: 'Asia/Rangoon',
  Bangkok: 'Asia/Bangkok',
  Hanoi: 'Asia/Bangkok',
  Jakarta: 'Asia/Jakarta',
  Krasnoyarsk: 'Asia/Krasnoyarsk',
  Novosibirsk: 'Asia/Novosibirsk',
  Beijing: 'Asia/Shanghai',
  Chongqing: 'Asia/Chongqing',
  'Hong Kong': 'Asia/Hong_Kong',
  Irkutsk: 'Asia/Irkutsk',
  'Kuala Lumpur': 'Asia/Kuala_Lumpur',
  Perth: 'Australia/Perth',
  Singapore: 'Asia/Singapore',
  Taipei: 'Asia/Taipei',
  Ulaanbaatar: 'Asia/Ulaanbaatar',
  Osaka: 'Asia/Tokyo',
  Sapporo: 'Asia/Tokyo',
  Seoul: 'Asia/Seoul',
  Tokyo: 'Asia/Tokyo',
  Yakutsk: 'Asia/Yakutsk',
  Adelaide: 'Australia/Adelaide',
  Darwin: 'Australia/Darwin',
  Brisbane: 'Australia/Brisbane',
  Canberra: 'Australia/Canberra',
  Guam: 'Pacific/Guam',
  Hobart: 'Australia/Hobart',
  Melbourne: 'Australia/Melbourne',
  'Port Moresby': 'Pacific/Port_Moresby',
  Sydney: 'Australia/Sydney',
  Vladivostok: 'Asia/Vladivostok',
  Magadan: 'Asia/Magadan',
  'New Caledonia': 'Pacific/Noumea',
  'Solomon Is.': 'Pacific/Guadalcanal',
  Srednekolymsk: 'Asia/Srednekolymsk',
  Auckland: 'Pacific/Auckland',
  Fiji: 'Pacific/Fiji',
  Kamchatka: 'Asia/Kamchatka',
  'Marshall Is.': 'Pacific/Majuro',
  Wellington: 'Pacific/Auckland',
  'Chatham Is.': 'Pacific/Chatham',
  "Nuku'alofa": 'Pacific/Tongatapu',
  Samoa: 'Pacific/Apia',
  'Tokelau Is.': 'Pacific/Fakaofo',
} as Record<(typeof tzList)[number], string>;
