import { useTranslation } from '@hopin-team/provider-translation';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useIsFreeTrial } from '@/hooks/useIsFreeTrial';
import {
  CustomDomainsShowPage,
  FLAG_ANALYTICS_ATTENDEE_ENGAGEMENT_SCORE_UI,
  FLAG_CUSTOM_DOMAINS_SHOW_PAGE,
  FLAG_ENABLE_GLOBAL_TAGS,
  FLAG_ENABLE_SURVEY_CUSTOMIZATION,
  FLAG_ENABLE_TICKET_GROUP_DEPRECATION,
  FLAG_ENABLED_EVENT_DASHBOARD_PAGES,
  FLAG_ONSITE_EVENT_DASHBOARD_STREAMLINE,
  FLAG_PAGE_BUILDER_EDIT_SPONSORS,
  FLAG_REORDER_EVENT_DASHBOARD_NAV_TABS,
} from '@/utils/flags';

import { useRootSelector } from '../../../store';
import {
  getEvent,
  getFlags,
  getNavigationView,
  getNewRegistration,
} from '../../../store/selectors';
import { iconMap } from '../constants';
import makeNavTree from '../nav-config';
import { NavItemShape, NavTreeShape } from '../types';
import { NavItem } from './NavItem';

export const NavTree = () => {
  const { t } = useTranslation();
  const flags = useRootSelector(getFlags);
  const event = useRootSelector(getEvent);
  const location = useLocation();
  const navigationView = useRootSelector(getNavigationView);
  const newRegistrations = useRootSelector(getNewRegistration);
  const { isFreeTrial, isDraft } = useIsFreeTrial();

  const [navTree, setNavTree] = useState<NavTreeShape | null>(null);
  const [expandedNavItem, setExpandedNavItem] = useState<{
    key: string;
    rootKey: string;
    tree: NavItemShape;
  } | null>(null);
  const activeNavItem = useRef<{
    key: string;
    tree: NavItemShape;
  } | null>();

  const creationEnableGlobalTags = Boolean(flags?.[FLAG_ENABLE_GLOBAL_TAGS]);
  const surveyBuilderEnabled = Boolean(
    flags?.[FLAG_ENABLE_SURVEY_CUSTOMIZATION],
  );
  const ticketGroupsDeprecated = Boolean(
    flags?.[FLAG_ENABLE_TICKET_GROUP_DEPRECATION],
  );
  const reorderTabs = Boolean(flags?.[FLAG_REORDER_EVENT_DASHBOARD_NAV_TABS]);
  const showSponsorsForLiteEvents = Boolean(
    flags?.[FLAG_PAGE_BUILDER_EDIT_SPONSORS],
  );
  const showCustomDomainsPage: CustomDomainsShowPage =
    flags?.[FLAG_CUSTOM_DOMAINS_SHOW_PAGE];
  const showEngagement = Boolean(
    flags?.[FLAG_ANALYTICS_ATTENDEE_ENGAGEMENT_SCORE_UI],
  );
  const engagementPageMigrated =
    flags &&
    flags[FLAG_ENABLED_EVENT_DASHBOARD_PAGES]?.includes('engagement_summary');
  const communicationsPageMigrated =
    flags &&
    flags[FLAG_ENABLED_EVENT_DASHBOARD_PAGES]?.includes('communications');
  const announcementsPageMigrated =
    flags &&
    flags?.[FLAG_ENABLED_EVENT_DASHBOARD_PAGES]?.includes('announcements');
  const onsiteEventDashboardStreamline = Boolean(
    flags?.[FLAG_ONSITE_EVENT_DASHBOARD_STREAMLINE],
  );
  const isAppAreaVisible =
    event?.organization.currentPaymentPlan.name !== 'Free';

  const { newRegistrationsDashboardEnabled, newRegistrationsUrl } =
    newRegistrations;

  useEffect(() => {
    if (
      event &&
      navigationView &&
      newRegistrationsDashboardEnabled !== null &&
      newRegistrationsUrl !== null
    ) {
      const { tree, resolveTree } = makeNavTree({
        event,
        isAppAreaVisible,
        creationEnableGlobalTags,
        surveyBuilderEnabled,
        navigationView,
        newRegistrationsDashboardEnabled,
        newRegistrationsUrl,
        ticketGroupsDeprecated,
        reorderTabs,
        showSponsors: showSponsorsForLiteEvents,
        showCustomDomainsPage,
        showEngagement,
        engagementPageMigrated,
        communicationsPageMigrated,
        announcementsPageMigrated,
        onsiteEventDashboardStreamline,
      });
      setNavTree(tree);
      const resolvedTree = resolveTree(window.location);
      setExpandedNavItem(resolvedTree);
      activeNavItem.current = resolvedTree;
    }
  }, [
    creationEnableGlobalTags,
    event,
    isAppAreaVisible,
    navigationView,
    newRegistrationsDashboardEnabled,
    newRegistrationsUrl,
    surveyBuilderEnabled,
    location,
    ticketGroupsDeprecated,
    reorderTabs,
    showSponsorsForLiteEvents,
    showCustomDomainsPage,
    showEngagement,
    engagementPageMigrated,
    communicationsPageMigrated,
    announcementsPageMigrated,
    onsiteEventDashboardStreamline,
  ]);

  const handleNavItemClick = (navItem: any) => {
    if (
      expandedNavItem?.key === navItem.key ||
      expandedNavItem?.rootKey === navItem.key ||
      expandedNavItem?.tree?.title === navItem.tree.title
    ) {
      setExpandedNavItem(null);
    } else {
      setExpandedNavItem(navItem);
    }
  };

  return (
    <div
      className="bg-nav-background w-full overflow-y-auto"
      data-menu-version="v3"
      data-testid="nav-tree"
    >
      {navTree
        ? Object.entries(navTree.children).map(([title, value]) => (
            <NavItem
              currentActiveItem={activeNavItem.current}
              icon={iconMap[title]}
              iconSize="large"
              isExpanded={
                (title === expandedNavItem?.key ||
                  title === expandedNavItem?.tree?.title ||
                  title === expandedNavItem?.rootKey) &&
                !!value.children
              }
              key={title}
              navItemData={value}
              onClick={handleNavItemClick}
              title={t(title)}
              topLevelTranslationKey={title}
              gatePaidFeatures={isFreeTrial && isDraft}
            />
          ))
        : null}
    </div>
  );
};
