import { CustomDomainsShowPage } from '@/utils/flags';

import { getDomainPageEntry } from '../nav-config';
import { NavTab, TemplateModifier } from '../types';

type Registration = {
  'registrations-tickets': object;
  'ticket-groups': object;
  'registration-form': object;
  'registration-landing-page': object;
  details?: object;
  'magic-link-invites'?: object;
  registrants?: object;
};

type People =
  | {
      speakers: object;
      registrants: object;
      'magic-link-invites': object;
    }
  | Record<string, never>;

type Setup = {
  basics: object;
  theme: object;
  'venue-controls': object;
  speakers?: object;
};

export const getOnsiteEventWizardTemplates = ({
  reorderTabs,
  showCustomDomainsPage = CustomDomainsShowPage.CLASSIC,
}: {
  reorderTabs: boolean;
  showCustomDomainsPage?: CustomDomainsShowPage;
}): Record<TemplateModifier, NavTab> => {
  // Tabs
  let setup: Setup = {
    basics: {},
    theme: {},
    'venue-controls': {
      __title: 'access-and-settings',
    },
  };

  let registration: Registration = {
    'registrations-tickets': {},
    'ticket-groups': {},
    'registration-form': {},
    'registration-landing-page': {},
    details: {},
  };

  const content = {
    reception: {},
    schedule: {},
    stage: {},
    stages: {},
    sessions: {},
    'venue-controls-people': {},
    surveys: {},
    expo: {},
    sponsors: {},
    tags: {},
  };

  const features = {
    'floor-plan': {},
    'check-in-areas': {},
    'kiosk-mode': {},
    badges: {},
    signatures: {},
  };

  let people: People = {
    registrants: {},
    speakers: {},
    'magic-link-invites': {},
  };

  const marketing = {
    emails: {},
    'email-attendees': {},
    'customize-emails': {},
    ...getDomainPageEntry(showCustomDomainsPage),
    'sharing-and-tracking': {},
    'utm-codes': {},
  };

  const analytics = {
    reports: {},
    'view-polls': {},
    'expo-summary': {},
  };

  // reorder
  if (reorderTabs) {
    registration = {
      'registration-landing-page': {},
      'registration-form': {},
      'registrations-tickets': {},
      'ticket-groups': {},
      registrants: {},
      'magic-link-invites': {},
    };

    people = {};

    setup = {
      ...setup,
      speakers: {},
    };
  }

  // Templates
  const onsite_pre_event = {
    overview: {},
    setup,
    registration,
    content,
    'onsite-features': features,
    people,
    marketing,
    more: {
      'customize-text': {},
      'host-information': {},
      analytics,
    },
  };

  const onsite_during_event = {
    overview: {},
    setup,
    registration,
    content,
    'onsite-features': features,
    people,
    marketing,
    analytics,
    more: {
      'customize-text': {},
      'host-information': {},
    },
  };

  const onsite_after_event = {
    overview: {},
    people,
    analytics,
    more: {
      'customize-text': {},
      'host-information': {},
      setup,
      registration,
      content,
      'onsite-features': features,
      marketing,
    },
  };

  return {
    before: onsite_pre_event,
    during: onsite_during_event,
    after: onsite_after_event,
  };
};
