export const ExclamationCircleIcon = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={16}
    height={16}
    className={className}
  >
    <path
      d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm-.75 5.5a.75.75 0 0 1 1.5 0v4.571a.75.75 0 0 1-1.5 0Zm.77 9a1 1 0 0 1-.01-2h.01a1 1 0 0 1 0 2Z"
      fill="currentColor"
    />
  </svg>
);
