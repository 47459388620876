import cx from 'classnames';

type ContentProps = {
  children: React.ReactNode;
  restrictedWidth?: boolean;
};

export const Content = ({
  children,
  restrictedWidth = false,
}: ContentProps) => {
  return (
    <div className="h-full w-full px-6">
      <div
        className={cx('mx-auto text-gray-900', {
          'max-w-[1552px]': restrictedWidth,
        })}
      >
        {children}
      </div>
    </div>
  );
};
