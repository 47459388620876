import { SVGProps } from 'react';

export const Minus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="1.945"
      height="18"
      x="21"
      y="11"
      fill="currentColor"
      rx="0.972"
      transform="rotate(90 21 11)"
    />
  </svg>
);
