import { SVGProps } from 'react';

export const Coupon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      opacity="0.4"
      d="M19 12C19 13.1 19.9 14 21 14V17C21 19 20 20 18 20H6C4 20 3 19 3 17V14C4.1 14 5 13.1 5 12C5 10.9 4.1 10 3 10V7C3 5 4 4 6 4H18C20 4 21 5 21 7V10C19.9 10 19 10.9 19 12Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M15.75 4V7.5C15.75 7.91 15.41 8.25 15 8.25C14.59 8.25 14.25 7.91 14.25 7.5V4H15.75ZM15.75 13.25V10.75C15.75 10.336 15.414 10 15 10C14.586 10 14.25 10.336 14.25 10.75V13.25C14.25 13.664 14.586 14 15 14C15.414 14 15.75 13.664 15.75 13.25ZM14.25 20V16.5C14.25 16.09 14.59 15.75 15 15.75C15.41 15.75 15.75 16.09 15.75 16.5V20H14.25Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
