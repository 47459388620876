import { NavTab, TemplateModifier } from '../types';

type Registration = {
  'registrations-tickets': object;
  'ticket-groups': object;
  'registration-form': object;
  'registration-landing-page': object;
  details?: object;
  'magic-link-invites'?: object;
  registrants?: object;
};

type People =
  | {
      speakers: object;
      registrants: object;
      'magic-link-invites': object;
    }
  | Record<string, never>;

type Venue = {
  reception: object;
  schedule: object;
  stage: object;
  stages: object;
  session: object;
  sessions: object;
  'venue-controls-people': object;
  networking: object;
  partners: object;
  tags: object;
  surveys: object;
  'venue-controls': object;
  'app-area': object;
  sponsors?: object;
  speakers?: object;
};

const getSimpleEventWizardTemplates = ({
  reorderTabs,
  withSponsors,
}: {
  reorderTabs: boolean;
  withSponsors: boolean;
}): Record<TemplateModifier, NavTab> => {
  // Tabs
  const setup = {
    basics: {},
    theme: {},
    registration: {},
  };

  let registration: Registration = {
    'registrations-tickets': {},
    'ticket-groups': {},
    'registration-form': {},
    'registration-landing-page': {},
    details: {},
  };

  let venue: Venue = {
    reception: {},
    schedule: {},
    stage: {},
    stages: {},
    session: {},
    sessions: {},
    'venue-controls-people': {},
    networking: {},
    partners: {},
    tags: {},
    surveys: {},
    'venue-controls': {},
    'app-area': {},
    ...(withSponsors ? { sponsors: {} } : {}),
  };

  let people: People = {
    registrants: {},
    speakers: {},
    'magic-link-invites': {},
  };

  const marketing = {
    emails: {},
    'email-attendees': {},
    'customize-emails': {},
  };

  const analytics = {
    engagement: {},
    reports: {},
    registrations: {},
    connections: {},
    'view-polls': {},
    'expo-summary': {},
  };

  // reorder
  if (reorderTabs) {
    registration = {
      'registration-landing-page': {},
      'registration-form': {},
      'registrations-tickets': {},
      'ticket-groups': {},
      registrants: {},
      'magic-link-invites': {},
    };

    people = {};

    venue = {
      ...venue,
      speakers: {},
    };
  }

  // Templates
  const simpleEventConfig_during_event = {
    overview: {},
    setup,
    registration,
    venue,
    people,
    marketing,
    analytics,
  };

  const simpleEventConfig_after_event = {
    overview: {},
    analytics,
    people,
    more: {
      setup,
      registration,
      venue,
      marketing,
    },
  };

  return {
    before: simpleEventConfig_during_event,
    during: simpleEventConfig_during_event,
    after: simpleEventConfig_after_event,
  };
};

export const getSimpleEventWizardTemplatesWithSponsors = ({
  reorderTabs,
}: {
  reorderTabs: boolean;
}) => {
  return getSimpleEventWizardTemplates({
    reorderTabs,
    withSponsors: true,
  });
};

export const getSimpleEventWizardTemplatesWithNoSponsors = ({
  reorderTabs,
}: {
  reorderTabs: boolean;
}) => {
  return getSimpleEventWizardTemplates({
    reorderTabs,
    withSponsors: false,
  });
};
