import { SVGProps } from 'react';

export const Envelope3D = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      opacity="0.4"
      d="M4.66699 20H15.334C17.112 20 18.001 19.111 18.001 17.333V9.666C18.001 7.888 17.112 6.99899 15.334 6.99899H4.66699C2.88899 6.99899 2 7.888 2 9.666V17.333C2 19.111 2.88899 20 4.66699 20Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M21 6.67V14.33C21 16.11 20.1101 17 18.3301 17H18V9.67C18 7.89 17.1101 7 15.3301 7H5V6.67C5 4.89 5.88992 4 7.66992 4H18.3301C20.1101 4 21 4.89 21 6.67Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M10 14.997C9.653 14.997 9.30601 14.889 9.01001 14.674L4.42505 11.34C4.09005 11.096 4.01601 10.627 4.26001 10.292C4.50301 9.95903 4.97111 9.88303 5.30811 10.126L9.89307 13.46C9.95707 13.507 10.0429 13.507 10.1079 13.46L14.6931 10.126C15.0301 9.88203 15.498 9.95803 15.741 10.292C15.985 10.627 15.9109 11.096 15.5759 11.34L10.991 14.674C10.694 14.889 10.347 14.997 10 14.997Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
