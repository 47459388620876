import { SVGProps } from 'react';

export const Checkmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.843 7.62L9.617 18.597a.535.535 0 01-.752 0l-5.708-5.582a.512.512 0 010-.735l.741-.725a.535.535 0 01.752 0l4.586 4.484 10.114-9.89a.549.549 0 01.752 0l.741.735a.512.512 0 010 .736z"
    ></path>
  </svg>
);
