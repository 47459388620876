export type VirtulSpace = {
  segment_type: 'sessions' | 'stage';
  name: string;
  virtual_space_external_id: string;
  schedule_item_external_id: string | null;
  schedule_name: string | null;
  schedule_time_start: string | null;
  schedule_time_end: string | null;
};

export type RecordingStateKeysUnion = `${RECORDING_STATE_KEYS}`;

export enum RECORDING_STATE_KEYS {
  main = 'savedRecordings',
  upload = 'myUploads',
  cut = 'savedRecordings',
}

export enum RECORDING_CATEGORY {
  MAIN = 'main',
  BACKUP = 'backup',
  REHEARSAL = 'rehearsal',
  CUT = 'cut',
  DEFAULT = 'default',
  UPLOAD = 'upload',
}

type RecordingCategoryUnion = `${RECORDING_CATEGORY}`;

export enum VIDEO_EDIT_STATUSES {
  success = 'success',
  failure = 'failure',
  processing = 'processing',
  trimmingRequested = 'trimming_requested',
}

type VideoEditStatusUnion = `${VIDEO_EDIT_STATUSES}`;

export const EVENT_PART = {
  STAGE: 'stage',
  SESSION: 'session',
};

export const AREA_TYPE = {
  STAGE: 'stage',
  SESSION: 'session',
};

export const TYPES_WITH_SECONDARY_LINKS = [
  EVENT_PART.STAGE,
  EVENT_PART.SESSION,
  AREA_TYPE.STAGE,
  AREA_TYPE.SESSION,
];

export const MAX_TITLE_LENGTH = 300;

export const HOURS_FOR_REPLAY_PROCESSING = 2;

export const SECONDS_IN_ONE_MINUTE = 60;

export const SECONDS_IN_ONE_HOUR = 60 * SECONDS_IN_ONE_MINUTE;

export type RecordingGroupsFilters = {
  eventPart?: string;
  date?: string;
  videoAreaId?: string;
};

export type VideoArea = {
  id: string;
  name: string;
  type: string;
};

export type RecordingAreasResponse = {
  recordings_upload_enabled: boolean;
  video_areas: VideoArea[];
};

export type Simulive = {
  id: number;
  uuid: string;
  schedule_id: number;
  video_channel_id: number;
};

export type Recording = {
  playableUrl: string | null;
  id: string;
  title: string;
  description: string;
  category: RecordingCategoryUnion;
  downloadUrl: string | null;
  failure_reason: string | null;
  thumbnail: string | null;
  createdAt: string;
  startedAt: string;
  endedAt?: string;
  duration: number;
  eventPart: {
    id: string;
    name?: string;
    type: string;
  };
  published: boolean;
  parentRecordingId?: string;
  status: VideoEditStatusUnion;
  statusChangedAt: string | null;
  simulives: Simulive[];
};

export type RecordingResponse = {
  id: string;
  title: string;
  description: string;
  category: RecordingCategoryUnion;
  duration: number;
  failure_reason: string | null;
  created_at: string;
  started_at: string;
  thumbnail: string | null;
  ended_at?: string;
  playable_url: string | null;
  download_url: string | null;
  event_part_name: string;
  event_part_type: string;
  event_part_id: string;
  published: boolean;
  parent_recording_id: string | null;
  status: VIDEO_EDIT_STATUSES;
  status_changed_at: string | null;
  simulives: Simulive[];
};

export type RecordingsResponse = {
  videoAreaId: string;
  data: RecordingResponse[];
};

export type ViewRecordingResponse = RecordingResponse & {
  description: string;
  category: RecordingCategoryUnion;
  related: RecordingResponse[];
};

export type VirtualSpacesResponse = VirtulSpace[];

export type ViewRecording = Recording & {
  description?: string;
  category: string;
  related: Recording[];
};

export type RecordingsFilters = {
  videoAreaType?: string;
  videoAreaId: string;
  category: string;
};

export type ManageRecordings = {
  recordingGroups: {
    isLoading: boolean;
    recordingsUploadEnabled: boolean;
    videoAreas: VideoArea[];
  };
  recordings: {
    isLoading: Record<string, boolean>;
    savedRecordings: Record<string, Recording[]>;
    isLoadingMyUploads: boolean;
    myUploads: Recording[];
  };
  viewRecording: {
    isLoading: boolean;
    isEditLoading: boolean;
    isDeleteLoading: boolean;
    isDeleteConfirmationShowing: boolean;
    isEditPanelShowing: boolean;
    recording: ViewRecording | null;
  };
  virtualSpaces: {
    query: string | null;
    data: VirtulSpace[];
    isLoading: boolean;
  };
  uploadRecording: {
    isUploading: boolean;
    progress: number;
    completed: boolean;
  };
};

export type UploadRecordingFormValues = {
  name: string | null;
  segment: VirtulSpace | null;
  publishToAttendees: boolean;
  createSimulive: boolean;
  recordingFile: File | null;
  duration: number | null;
};

export type RecordingUploadPayload = {
  data: {
    name: string;
    publishToAttendees: boolean;
    createSimulive: boolean;
    recordingFile: File;
    segment: VirtulSpace | null;
    duration: number | null;
  };
};

export type RecordingUploadResponse = {
  created_at: string;
  ended_at: string;
  event_id: number;
  id: number;
  publlished: boolean;
  recordable_id: number;
  recordable_type: string;
  started_at: string;
  title: string;
  updated_at: string;
  uuid: string;
};

export type S3CredentialsResponse = {
  params: Record<string, string>;
  presigned_url: string;
};

export type ErrorResponse = {
  errors: Array<{
    code: string;
    detail: string;
    title: string;
    source: {
      field: 'string';
      resource: string | null;
    };
  }>;
};
