import { useTranslation } from '@hopin-team/provider-translation';
import cx from 'classnames';
import { LegacyRef, useEffect, useRef, useState } from 'react';

import { CaretRight } from '../../../components/icons';
import { useRootDispatch } from '../../../store';
import { NavItemShape } from '../types';
import { SubNavItem } from './SubNavItem';

type NavItemProps = {
  currentActiveItem: any;
  icon: any;
  iconSize: 'medium' | 'large';
  isExpanded: boolean;
  navItemData: NavItemShape;
  onClick: (navItem: any) => void;
  title: string;
  topLevelTranslationKey: string;
  isDeepLevel?: boolean;
  tabIndex?: number;
  gatePaidFeatures?: boolean;
};

export const NavItem = ({
  currentActiveItem,
  icon,
  iconSize = 'medium',
  isExpanded,
  navItemData,
  onClick,
  title,
  topLevelTranslationKey,
  isDeepLevel,
  tabIndex = 0,
  gatePaidFeatures,
}: NavItemProps) => {
  const { t } = useTranslation();
  const dispatch = useRootDispatch();
  const isLink = typeof navItemData === 'string';
  const hasChildren = !isLink && 'children' in navItemData;
  const activeItemRef = useRef<HTMLButtonElement>();
  const [deepExpanded, setDeepExpanded] = useState<string | null>(
    currentActiveItem?.tree.title ?? null,
  );

  const Icon = icon;

  const handleClick = () => {
    if (isLink) {
      dispatch({
        type: 'link clicked',
        payload: {
          link: navItemData,
        },
      });
    } else {
      onClick({ key: title, tree: navItemData });
    }
  };

  const scrollToActiveItem: LegacyRef<HTMLButtonElement> = elem => {
    if (elem && !activeItemRef.current) {
      elem.scrollIntoView({ block: 'nearest' });
      activeItemRef.current = elem;
    }
  };

  const isActive =
    topLevelTranslationKey === currentActiveItem.key ||
    topLevelTranslationKey === currentActiveItem.tree.title;

  useEffect(() => {
    if (!isExpanded) {
      setDeepExpanded(null);
    }
  }, [isExpanded]);

  return (
    <div className={cx('mt-4 first:mt-2', { 'mx-2': !isDeepLevel })}>
      <button
        tabIndex={tabIndex}
        className={cx(
          'h-15 hover:bg-nav-hover group relative flex w-full min-w-0 items-center gap-3 rounded-lg px-4 py-2 leading-7 text-gray-800 hover:text-gray-900',
          {
            'font-bold hover:text-gray-900':
              isActive || (isDeepLevel && isExpanded),
            'text-primary bg-nav-hover': isActive && !isExpanded,
          },
        )}
        onClick={handleClick}
      >
        <Icon
          className={cx('flex items-center justify-center', {
            'group-hover:invisible': hasChildren,
            invisible: isExpanded,
            'h-4 w-4': iconSize === 'medium',
            'h-5 w-5': iconSize === 'large',
          })}
        />
        {hasChildren ? (
          <div
            className={cx(
              'bg-nav-background group-hover:bg-nav-hover absolute left-4 flex h-5 w-5 items-center justify-center rounded-sm',
              { 'invisible bg-gray-800 group-hover:visible': !isDeepLevel },
            )}
          >
            <CaretRight
              className={cx('transition-transform duration-200 ease-out', {
                'visible rotate-90': isExpanded,
              })}
            />
          </div>
        ) : null}
        <div>{t(`${'nav-items'}.${title}`)}</div>
      </button>

      {hasChildren && (
        <ul
          className={cx(
            'm-0 overflow-hidden transition-[max-height] duration-200 ease-out',
            {
              'max-h-0': !isExpanded,
              'max-h-[9999px]': isExpanded,
            },
          )}
        >
          {Object.entries(navItemData.children).map(
            ([childTitle, navItemData]) => {
              const isLink = typeof navItemData === 'string';
              const hasChildren = !isLink && 'children' in navItemData;
              const childTabIndex = isExpanded ? 0 : -1;

              if (hasChildren) {
                return (
                  <NavItem
                    key={childTitle}
                    currentActiveItem={currentActiveItem}
                    isDeepLevel
                    icon={icon}
                    iconSize={iconSize}
                    isExpanded={deepExpanded === childTitle}
                    navItemData={navItemData}
                    onClick={() => {
                      if (deepExpanded === childTitle) {
                        setDeepExpanded(null);
                      } else {
                        setDeepExpanded(childTitle);
                      }
                    }}
                    title={childTitle}
                    topLevelTranslationKey={childTitle}
                    tabIndex={childTabIndex}
                    gatePaidFeatures={gatePaidFeatures}
                  />
                );
              } else {
                return (
                  <SubNavItem
                    childTitle={childTitle}
                    key={childTitle}
                    link={navItemData}
                    currentActiveItem={currentActiveItem}
                    scrollToActiveItem={scrollToActiveItem}
                    tabIndex={childTabIndex}
                    gatePaidFeatures={gatePaidFeatures}
                  />
                );
              }
            },
          )}
        </ul>
      )}
    </div>
  );
};
