import segmentPlugin from '@analytics/segment';
import { getSegmentWriteKey } from '@hopin-team/config-hopin-constants';
import {
  createClientConsentCallback,
  initAnalytics,
} from '@hopin-team/provider-analytics';

const plugins = [
  segmentPlugin({
    enabled: true,
    writeKey: getSegmentWriteKey(),
  }),
];

export const clientCallback = createClientConsentCallback({
  ANALYTICS: ['segment'],
});

export const analyticsClient = initAnalytics({ plugins });
