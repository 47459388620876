import { memoize } from 'proxy-memoize';

import { RootState } from './state';

export const getAuth = (state: RootState) => state.auth;
export const getEvent = (state: RootState) => state.event;
export const getEventExternalId = (state: RootState) =>
  getEvent(state)?.externalId ?? null;
export const getUser = (state: RootState) => state.user;
export const getFlags = (state: RootState) => state.flags;
export const getIsImpersonating = (state: RootState) => state.isImpersonating;
export const getUserId = (state: RootState) => getUser(state)?.id ?? null;
export const getUserExternalId = (state: RootState) =>
  getUser(state)?.externalId ?? null;
export const getNavigationView = (state: RootState) =>
  state.navigationView || 'advanced';
export const getNewRegistration = memoize((state: RootState) => ({
  newRegistrationsDashboardEnabled: state.newRegistrationsDashboardEnabled,
  newRegistrationsUrl: state.newRegistrationsUrl,
}));
export const getCurrentPaymentPlan = (state: RootState) =>
  state.event?.organization.currentPaymentPlan;
export const getPaymentPlanFeatures = (state: RootState) =>
  state.event?.organization.currentPaymentPlan.features;
export const getOrganization = (state: RootState) => state.event?.organization;
export const getPusherInstance = (state: RootState) => state.pusher;
export const getPlatformUrls = (state: RootState) => state.platformUrls;
export const getTickets = (state: RootState) => state.tickets;
export const getUpgradeDialogOpen = (state: RootState) =>
  state.upgradeDialogOpen;
export const getReportError = (state: RootState) => state.report.error;
