import { SVGProps } from 'react';

export const Venue = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <g fill="currentColor">
      <path d="M12 1.5a6 6 0 00-6 6c0 3.946 3.9 5.781 4.39 6.221.498.45.91 1.35 1.078 1.921.08.277.307.417.532.423a.565.565 0 00.532-.423c.167-.57.579-1.472 1.079-1.92C14.099 13.281 18 11.445 18 7.5a6 6 0 00-6-6zm0 7.714a1.714 1.714 0 110-3.427 1.714 1.714 0 010 3.427z"></path>
      <path
        d="M4.86 9.75H3.75v10.5h16.5V9.75h-1.11c.225-.675.36-1.425.36-2.25h.75a2.25 2.25 0 012.25 2.25v10.5h.75A.75.75 0 0124 21v.75a.75.75 0 01-.75.75H.75a.75.75 0 01-.75-.75V21a.75.75 0 01.75-.75h.75V9.75A2.25 2.25 0 013.75 7.5h.75a7.1 7.1 0 00.36 2.25z"
        opacity="0.72"
      />
    </g>
  </svg>
);
