import { SVGProps } from 'react';

export const Play = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.278 11.768v.49c-.002.468-.248.9-.649 1.14l-15.685 9.13c-.946.56-1.437.56-1.857.316l-.439-.246A1.332 1.332 0 012 21.494V2.532c.001-.467.247-.9.648-1.139l.439-.245c.42-.246.91-.246 2.138.473l15.404 9.008c.401.24.647.672.649 1.139z"
    />
  </svg>
);
