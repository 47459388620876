import type { ScriptInitializer } from '@slicesjs/react-core';

import packageJson from '../../../package.json';

const stripCaret = (version = '') => version.replace('^', '');

export const loadWithWindow: ScriptInitializer = async (scope, module) => {
  const container = (window as Record<string, any>)[scope];
  if (!container?.init || !container?.get) return null;

  container.init({
    react: {
      [stripCaret(packageJson?.dependencies?.react)]: {
        get: () => Promise.resolve().then(() => () => require('react')),
      },
    },
    'react-dom': {
      [stripCaret(packageJson?.dependencies?.['react-dom'])]: {
        get: () => Promise.resolve().then(() => () => require('react-dom')),
      },
    },
  });

  return container.get(module).then((factory: any) => factory());
};
