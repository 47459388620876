import { SVGProps } from 'react';

export const Copy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.2 16.8V2.4A2.4 2.4 0 0016.8 0H2.4A2.4 2.4 0 000 2.4v14.4a2.4 2.4 0 002.4 2.4h14.4a2.4 2.4 0 002.4-2.4zM2.4 2.4h14.4v14.4H2.4V2.4zm19.2 16.8V4.8A2.4 2.4 0 0124 7.2v12a4.8 4.8 0 01-4.8 4.8h-12a2.4 2.4 0 01-2.4-2.4h14.4a2.4 2.4 0 002.4-2.4z"
      clipRule="evenodd"
    />
  </svg>
);
