import { SVGProps } from 'react';

export const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M23.704 20.883a1 1 0 010 1.42l-1.4 1.401a1 1 0 01-1.42 0L12 14.821l-8.883 8.883a1 1 0 01-1.42 0l-1.401-1.4a1 1 0 010-1.42L9.179 12 .296 3.117a1 1 0 010-1.42l1.4-1.401a1 1 0 011.42 0L12 9.179 20.883.296a1 1 0 011.42 0l1.401 1.4a1 1 0 010 1.42L14.821 12l8.883 8.883z"
    />
  </svg>
);
