import cx from 'classnames';

import { Button } from '@/components/button/button';
import { ArrowBack } from '@/components/icons';

type HeaderProps = {
  title: string;
  onBackClick?: () => void;
  fullWidthLayout?: boolean;
  children?: React.ReactNode;
};

export const Header = ({
  title,
  onBackClick,
  fullWidthLayout,
  children,
}: HeaderProps) => {
  return (
    <>
      <header
        data-test="header-wrapper"
        data-testid="header"
        className="h-layout-header border-b border-b-gray-300 bg-white px-6 py-4"
      >
        <div
          data-testid="header-content"
          className={cx('m-auto flex items-center justify-between', {
            'max-w-6xl': !fullWidthLayout,
          })}
        >
          <div className="flex h-8 items-center gap-3">
            {onBackClick && (
              <Button
                data-testid="back-button"
                pattern="outline"
                kind="secondary"
                size="small"
                inline
                onClick={() => onBackClick()}
              >
                <ArrowBack className="h-3" />
              </Button>
            )}
            <h3
              data-testid="title"
              className="font-larsseit text-lg font-medium leading-[1.5625rem]"
            >
              {title}
            </h3>
          </div>
          {children}
        </div>
      </header>
    </>
  );
};
