import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { useTranslation } from '@hopin-team/provider-translation';
import cx from 'classnames';
import { Fragment, useEffect, useState } from 'react';

import { FLAG_ENABLE_FOUR_CHAR_LOCALES } from '@/utils/flags';

import { Button } from '../../../../components/button/button';
import { useRootSelector } from '../../../../store';
import { getFlags } from '../../../../store/selectors';
import { getLanguageOptions } from './language-options';

type LanguagePickerProps = {
  isShowing: boolean;
  onClose: () => void;
};

export const LanguagePicker = ({ isShowing, onClose }: LanguagePickerProps) => {
  const flags = useRootSelector(getFlags);
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const supportFourCharsLocales = Boolean(
    flags?.[FLAG_ENABLE_FOUR_CHAR_LOCALES],
  );
  const languageOptions = getLanguageOptions(t, supportFourCharsLocales);

  const handleSave = () => {
    i18n.changeLanguage(selectedLanguage);
    onClose();
  };

  useEffect(() => {
    if (!supportFourCharsLocales && i18n.language.length > 2) {
      i18n.changeLanguage(i18n.language.substring(0, 2));
    }

    if (!isShowing && selectedLanguage !== i18n.language) {
      setSelectedLanguage(i18n.language);
    }
  }, [
    selectedLanguage,
    isShowing,
    supportFourCharsLocales,
    i18n.language,
    i18n,
  ]);

  return (
    <>
      <Transition appear show={isShowing} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="p-4">
                    <Dialog.Title
                      as="h3"
                      className="my-4 text-center text-lg font-medium leading-6 text-gray-900"
                    >
                      {t('language-picker.heading')}
                    </Dialog.Title>
                    <p className="mb-4 text-center text-sm leading-6 text-gray-700">
                      {t('language-picker.description')}
                    </p>
                  </div>

                  <div className="bg-gray-100 p-4">
                    <RadioGroup
                      value={selectedLanguage}
                      onChange={setSelectedLanguage}
                    >
                      {languageOptions.map(({ option, title }) => (
                        <RadioGroup.Option
                          key={option}
                          value={option}
                          className={({ checked }) =>
                            cx(
                              'relative mb-2 flex cursor-pointer rounded-md border border-gray-300 px-5 py-4 focus:outline-none',
                              {
                                'border-blue-300 bg-blue-100': checked,
                              },
                            )
                          }
                        >
                          {({ checked }) => (
                            <div className="flex w-full items-center justify-between text-sm">
                              <div className="flex items-center gap-3">
                                {checked ? (
                                  <div className="bg-primary h-4 w-4 shrink-0 rounded-full p-[5px]">
                                    <div className="h-full w-full rounded-full bg-white"></div>
                                  </div>
                                ) : (
                                  <div className="shrink-0">
                                    <div className="h-4 w-4 shrink-0 rounded-full border border-gray-300 bg-white"></div>
                                  </div>
                                )}

                                <RadioGroup.Label as="p">
                                  {title}
                                </RadioGroup.Label>
                              </div>
                            </div>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </RadioGroup>
                  </div>

                  <div className="mt-4 p-4">
                    <Button type="button" onClick={handleSave}>
                      {t('language-picker.save')}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
