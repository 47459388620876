import { SVGProps } from 'react';

export const CaretRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.483 17.793a.7.7 0 0 1-.993 0l-.28-.28A.686.686 0 0 1 9 17.01V6.991a.686.686 0 0 1 .21-.504l.28-.28a.7.7 0 0 1 .993 0l5.304 5.303a.671.671 0 0 1 0 .98l-5.304 5.303z"
    />
  </svg>
);
