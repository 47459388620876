import cx from 'classnames';
import React, { ChangeEvent, Fragment } from 'react';

import { Checkmark, Minus } from '../icons';

type PropertiesToNever<T> = {
  [K in keyof T]?: never;
};

type LabelProps = {
  className?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  label: string | JSX.Element;
  labelClassName?: string;
  labelPosition?: 'inline' | 'top';
  checkboxHidden?: boolean;
};

type InputProps = {
  checked: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  name?: string;
  onChange?: (checked: boolean) => void;
};

type CheckboxProps =
  | (InputProps & PropertiesToNever<LabelProps>)
  | (InputProps & LabelProps);

/**
 * When _label_ prop is **passed**, the checkbox will be wrapped with a <label>
 * Otherwise, just the checkbox is returned
 */
const OptionalLabel = ({
  disabled,
  label,
  hideLabel,
  className,
  labelClassName,
  children,
  labelPosition = 'inline',
  checkboxHidden = false,
}: React.PropsWithChildren<CheckboxProps>) => {
  if (label === undefined) {
    return <>{children}</>;
  }

  return (
    <label
      className={cx(
        {
          'cursor-pointer': !disabled && !checkboxHidden,
          'inline-flex items-center': labelPosition === 'inline',
          'flex flex-col items-start': labelPosition === 'top',
        },
        className,
      )}
    >
      {labelPosition === 'top' && (
        <span
          className={cx('mb-3', labelClassName, {
            'sr-only': hideLabel,
            'text-gray-650': disabled,
          })}
        >
          {label}
        </span>
      )}
      {children}
      {labelPosition === 'inline' && (
        <span
          className={cx('ml-3', labelClassName, {
            'sr-only': hideLabel,
            'text-gray-650': disabled,
          })}
        >
          {label}
        </span>
      )}
    </label>
  );
};

export const Checkbox = (props: CheckboxProps) => {
  const {
    checked,
    disabled,
    indeterminate,
    name = 'checkbox',
    onChange,
    checkboxHidden = false,
  } = props;

  return (
    <OptionalLabel {...props}>
      <span
        className={cx(
          'relative flex h-4 w-4 flex-shrink-0 items-center justify-center',
          {
            'invisible ': checkboxHidden,
          },
        )}
      >
        <input
          name={name}
          type="checkbox"
          disabled={disabled}
          className="peer absolute inset-0 z-10 opacity-0"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            void (onChange ? onChange(event.target.checked) : 0)
          }
          checked={checked}
        />
        <span
          className={cx(
            'peer-checked:bg-primary peer-focus:ring-primary absolute inset-0 rounded-sm border border-solid border-gray-400 bg-white peer-checked:border-none peer-focus:outline-none peer-focus:ring-1 peer-disabled:bg-gray-200',
          )}
        />
        {indeterminate ? (
          <Minus className="relative h-3 w-3 text-white opacity-0 peer-checked:opacity-100 peer-disabled:opacity-0" />
        ) : (
          <Checkmark className="relative h-3 w-3 text-white opacity-0 peer-checked:opacity-100 peer-disabled:opacity-0" />
        )}
      </span>
    </OptionalLabel>
  );
};
