import { SVGProps } from 'react';

export const Onsite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.389 2h-.367a2.445 2.445 0 00-.94.183l-1.272.531c1.701 3.16 1.671 4.932.745 8.065v9.151l-3.666 1.528V15.32c-1.222.843-2.063 1.257-2.445 2.564v3.574l-4.888-2.041V17.87c-.52-1.59-.9-1.47-2.445-2.571v4.118l-3.667 1.527V10.751c-1.102-3.003-.582-5.492.44-7.509l-2.126.885c-.46.189-.76.638-.758 1.136V23.39c0 .337.274.611.611.611h.367c.323.002.642-.06.94-.183l5.415-2.262 5.195 2.164c.445.185.923.28 1.405.281H16.4c.482 0 .96-.096 1.405-.281l4.437-1.846c.46-.189.76-.639.758-1.136V2.61A.611.611 0 0022.389 2z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 0C8.134 0 5 3.32 5 7.415c0 4.877 4.551 7.144 5.121 7.688.582.555 1.063 1.668 1.258 2.374.094.342.359.516.621.523.263-.007.527-.181.621-.523.194-.705.675-1.819 1.258-2.374.57-.543 5.121-2.811 5.121-7.688C19 3.32 15.866 0 12 0zm0 9.533c-1.105 0-2-.948-2-2.118s.895-2.119 2-2.119 2 .948 2 2.119c0 1.17-.895 2.118-2 2.118z"
      clipRule="evenodd"
      opacity="0.72"
    />
  </svg>
);
