import { SVGProps } from 'react';

export const PenLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      opacity="0.4"
      d="M3 16V21H8L17.7871 11.2581L12.7419 6.21399L3 16Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M20.4143 5.81394L18.1863 3.58603C17.4043 2.80403 16.1352 2.80496 15.3542 3.58896L12.7412 6.21396L17.7861 11.259L20.4111 8.64597C21.1951 7.86497 21.1973 6.59594 20.4143 5.81394Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M21 21.75H14C13.586 21.75 13.25 21.414 13.25 21C13.25 20.586 13.586 20.25 14 20.25H21C21.414 20.25 21.75 20.586 21.75 21C21.75 21.414 21.414 21.75 21 21.75Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
