import { SVGProps } from 'react';

export const Recordings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 1.7H3a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-14a2 2 0 00-2-2zm-3.94 9.62l-3.89 2.6A.75.75 0 018 13.3V8.1a.75.75 0 011.17-.62l3.89 2.6a.74.74 0 010 1.24zM21 19.7v-14a2 2 0 012 2v12a4 4 0 01-4 4H7a2 2 0 01-2-2h14a2 2 0 002-2z"
      clipRule="evenodd"
    />
  </svg>
);
