import { RouteObject } from 'react-router-dom';

import { ErrorBoundary } from './components/ErrorBoundary';
import { Component as ErrorPage } from './pages/error';
import Root from './pages/root';

export const BASE_PATH_PREFIX = '/organisers/events';
const BASE = `${BASE_PATH_PREFIX}/:slug`;

const interpolate = (route: string, slug: string) =>
  route.replace(':slug', slug);

export const Routes = {
  organisersEventRoundtablesPath: (slug = '') => ({
    path: `${BASE}/roundtables`,
    as: interpolate(`${BASE}/roundtables`, slug),
  }),
  organisersEventSchedulesPath: (slug = '') => ({
    path: `${BASE}/schedules`,
    as: interpolate(`${BASE}/schedules`, slug),
  }),
  organisersEventCheckInAreaPath: (slug = '') => ({
    path: `${BASE}/check_in_area`,
    as: interpolate(`${BASE}/check_in_area`, slug),
  }),
  organisersEventKioskModePath: (slug = '') => ({
    path: `${BASE}/kiosk_mode`,
    as: interpolate(`${BASE}/kiosk_mode`, slug),
  }),
  organisersEventBadgeDesignerPath: (slug = '') => ({
    path: `${BASE}/badge_designer`,
    as: interpolate(`${BASE}/badge_designer`, slug),
  }),
  organisersEventFloorPlanPath: (slug = '') => ({
    path: `${BASE}/floor_plan`,
    as: interpolate(`${BASE}/floor_plan`, slug),
  }),
  organisersEventSignaturesPath: (slug = '') => ({
    path: `${BASE}/signatures`,
    as: interpolate(`${BASE}/signatures`, slug),
  }),
  organisersEventNewAttendeesPath: (slug = '') => ({
    path: `${BASE}/new_attendees`,
    as: interpolate(`${BASE}/new_attendees`, slug),
  }),
  organisersEventAttendeesPath: (slug = '') => ({
    path: `${BASE}/attendees`,
    as: interpolate(`${BASE}/attendees`, slug),
  }),
  organisersEventOverviewPath: (slug = '') => ({
    path: `${BASE}/overview`,
    as: interpolate(`${BASE}/overview`, slug),
  }),
  organisersEventSetupPath: (slug = '') => ({
    path: `${BASE}/setup`,
    as: interpolate(`${BASE}/setup`, slug),
  }),
  organisersEventBasicsPath: (slug = '') => ({
    path: `${BASE}/basics`,
    as: interpolate(`${BASE}/basics`, slug),
  }),
  designOrganisersEventPath: (slug = '') => ({
    path: `${BASE}/design`,
    as: interpolate(`${BASE}/design`, slug),
  }),
  registrationOrganisersEventPath: (slug = '') => ({
    path: `${BASE}/registration`,
    as: interpolate(`${BASE}/registration`, slug),
  }),
  receptionFormOrganisersEventPath: (slug = '') => ({
    path: `${BASE}/reception_form`,
    as: interpolate(`${BASE}/reception_form`, slug),
  }),
  organisersEventBackstagesPath: (slug = '') => ({
    path: `${BASE}/backstages`,
    as: interpolate(`${BASE}/backstages`, slug),
  }),
  newOrganisersEventBackstagePath: (slug = '') => ({
    path: `${BASE}/backstages/new`,
    as: interpolate(`${BASE}/backstages/new`, slug),
  }),
  editOrganisersEventBackstagePath: (slug: string, stageId: number) => ({
    path: `/organisers/events/:eventId/backstages/:stageId/edit`,
    as: `/organisers/events/${slug}/backstages/${stageId}/edit`,
  }),
  peopleOrganisersEventPath: (slug = '') => ({
    path: `${BASE}/people`,
    as: interpolate(`${BASE}/people`, slug),
  }),
  organisersEventSurveysPath: (slug = '') => ({
    path: `${BASE}/surveys`,
    as: interpolate(`${BASE}/surveys`, slug),
  }),
  networkingOrganisersEventPath: (slug = '') => ({
    path: `${BASE}/networking`,
    as: interpolate(`${BASE}/networking`, slug),
  }),
  organisersEventVendorsPath: (slug = '') => ({
    path: `${BASE}/vendors`,
    as: interpolate(`${BASE}/vendors`, slug),
  }),
  organisersEventSponsorsPath: (slug = '') => ({
    path: `${BASE}/sponsors`,
    as: interpolate(`${BASE}/sponsors`, slug),
  }),
  organisersEventEventTagsPath: (slug = '') => ({
    path: `${BASE}/tags`,
    as: interpolate(`${BASE}/tags`, slug),
  }),
  organisersEventVenueControlsPath: (slug = '') => ({
    path: `${BASE}/venue_controls`,
    as: interpolate(`${BASE}/venue_controls`, slug),
  }),
  organisersEventAppAreaPath: (slug = '') => ({
    path: `${BASE}/app_area`,
    as: interpolate(`${BASE}/app_area`, slug),
  }),
  organisersEventSpeakersPath: (slug = '') => ({
    path: `${BASE}/speakers`,
    as: interpolate(`${BASE}/speakers`, slug),
  }),
  organisersEventRedeemCodesPath: (slug = '') => ({
    path: `${BASE}/redeem_codes`,
    as: interpolate(`${BASE}/redeem_codes`, slug),
  }),
  organisersEventAnnouncementsPath: (slug = '') => ({
    path: `${BASE}/announcements`,
    as: interpolate(`${BASE}/announcements`, slug),
  }),
  organisersEventCommunicationsPath: (slug = '') => ({
    path: `${BASE}/communications`,
    as: interpolate(`${BASE}/communications`, slug),
  }),
  organisersEmailsPath: (slug = '') => ({
    path: `${BASE}/emails`,
    as: interpolate(`${BASE}/emails`, slug),
  }),
  organisersEventMarketingPath: (slug = '') => ({
    path: `${BASE}/marketing`,
    as: interpolate(`${BASE}/marketing`, slug),
  }),
  organisersEventUtmCodesPath: (slug = '') => ({
    path: `${BASE}/utm_codes`,
    as: interpolate(`${BASE}/utm_codes`, slug),
  }),
  organisersEventEventAffiliatesPath: (slug = '') => ({
    path: `${BASE}/affiliates`,
    as: interpolate(`${BASE}/affiliates`, slug),
  }),
  organisersEventRegistrationFieldsPath: (slug = '') => ({
    path: `${BASE}/registration_fields`,
    as: interpolate(`${BASE}/registration_fields`, slug),
  }),
  organisersEventHostInformationPath: (slug = '') => ({
    path: `${BASE}/host_information`,
    as: interpolate(`${BASE}/host_information`, slug),
  }),
  // old page
  organisersEventCustomDomainPath: (slug = '') => ({
    path: `${BASE}/custom_domain`,
    as: interpolate(`${BASE}/custom_domain`, slug),
  }),
  // new page
  organisersEventCustomDomainsPath: (slug = '') => ({
    path: `${BASE}/custom_domains`,
    as: interpolate(`${BASE}/custom_domains`, slug),
  }),
  organisersEventRecordingsPath: (slug = '') => ({
    path: `${BASE}/recordings`,
    as: interpolate(`${BASE}/recordings`, slug),
  }),
  organisersEventRecordingsCreateCutsPath: (slug = '', recordingId = '') => ({
    path: `${BASE}/recordings/:recordingId/cuts`,
    as: interpolate(`${BASE}/recordings/${recordingId}/cuts`, slug),
  }),
  organisersEventRecordingPath: (slug = '', id?: string) => ({
    path: `${BASE}/recordings/view/:id`,
    as: interpolate(`${BASE}/recordings/view/${id}`, slug),
  }),
  organisersEventRecordingsBackupPath: (
    slug = '',
    filterParams = new URLSearchParams(),
  ) => ({
    path: `${BASE}/recordings/backup`,
    as: interpolate(`${BASE}/recordings/backup?` + filterParams, slug),
  }),
  organisersEventRecordingsUploadsPath: (slug = '') => ({
    path: `${BASE}/recordings/uploads`,
    as: interpolate(`${BASE}/recordings/uploads`, slug),
  }),
  organisersEventRecordingsCutsPath: (slug = '') => ({
    path: `${BASE}/recordings/cuts`,
    as: interpolate(`${BASE}/recordings/cuts`, slug),
  }),
  organisersEventRecordingsRehearsalPath: (
    slug = '',
    filterParams = new URLSearchParams(),
  ) => ({
    path: `${BASE}/recordings/rehearsal`,
    as: interpolate(`${BASE}/recordings/rehearsal?` + filterParams, slug),
  }),
  organisersEventLiveAnalyticsPath: (slug = '') => ({
    path: `${BASE}/live_analytics`,
    as: interpolate(`${BASE}/live_analytics`, slug),
  }),
  organisersEventReportsPath: (slug = '') => ({
    path: `${BASE}/reports`,
    as: interpolate(`${BASE}/reports`, slug),
  }),
  organisersEventRegistrationsSummaryPath: (slug = '') => ({
    path: `${BASE}/registrations_summary`,
    as: interpolate(`${BASE}/registrations_summary`, slug),
  }),
  organiserRegistrationEventLandingPagePath: (slug = '') => ({
    path: `${BASE}/registration_page`,
    as: interpolate(`${BASE}/registration_page`, slug),
  }),
  organisersEventConnectionsSummaryPath: (slug = '') => ({
    path: `${BASE}/connections_summary`,
    as: interpolate(`${BASE}/connections_summary`, slug),
  }),
  organisersEventEngagementSummaryPath: (slug = '') => ({
    path: `${BASE}/engagement_summary`,
    as: interpolate(`${BASE}/engagement_summary`, slug),
  }),
  organisersEventConnectionsPath: (slug = '') => ({
    path: `${BASE}/connections`,
    as: interpolate(`${BASE}/connections`, slug),
  }),
  organisersEventEventPollsPath: (slug = '') => ({
    path: `${BASE}/event_polls`,
    as: interpolate(`${BASE}/event_polls`, slug),
  }),
  organisersEventExpoSummaryPath: (slug = '') => ({
    path: `${BASE}/expo_summary`,
    as: interpolate(`${BASE}/expo_summary`, slug),
  }),
  organizationsEventsPath: (orgId?: string) => ({
    path: `/organizations/events${orgId ? `?switch_to=${orgId}` : ''}`,
    as: '/organizations/events',
  }),
  textOrganisersEventPath: (slug = '') => ({
    path: `${BASE}/text`,
    as: interpolate(`${BASE}/text`, slug),
  }),
  organisersDuplicationsPath: (slug = '') => ({
    path: `${BASE}/duplications`,
    as: interpolate(`${BASE}/duplications`, slug),
  }),
  signOutPath: () => ({
    path: '/users/sign_out',
    as: '/users/sign_out',
  }),
  profileAccountPath: () => ({
    path: '/account/profile',
    as: '/account/profile',
  }),
  newOrganisersEventTimetableRoundtablePath: ({
    slug = '',
    timetableId,
  }: {
    slug?: string;
    timetableId?: number;
  }) => ({
    path: `${BASE}/schedule/:timetable_id/roundtables/new`,
    as: interpolate(`${BASE}/schedule/${timetableId}/roundtables/new`, slug),
  }),
  editOrganisersEventTimetableRoundtablePath: ({
    slug = '',
    timetableId,
    id,
  }: {
    slug?: string;
    timetableId?: number;
    id?: number;
  }) => ({
    path: `${BASE}/schedule/:timetable_id/roundtables/:id/edit`,
    as: interpolate(
      `${BASE}/schedule/${timetableId}/roundtables/${id}/edit`,
      slug,
    ),
  }),
  rtmpDetailsOrganisersEventTimetableRoundtablePath: ({
    slug = '',
    timetableId,
    id,
  }: {
    slug?: string;
    timetableId?: number;
    id?: number;
  }) => ({
    path: `${BASE}/:timetable_id/roundtables/:id/rtmp_details`,
    as: interpolate(
      `${BASE}/schedule/${timetableId}/roundtables/${id}/rtmp_details`,
      slug,
    ),
  }),
  bulkEditOrganisersEventRoundtablesBulkIndexPath: ({
    slug = '',
    sessionIds = [],
  }: {
    slug?: string;
    sessionIds: number[];
  }) => {
    const query = sessionIds.map(id => `roundtable_ids[]=${id}`).join('&');
    return {
      path: `${BASE}/roundtables/bulk_edit`,
      as: interpolate(`${BASE}/roundtables/bulk_edit?${query}`, slug),
    };
  },
  bulkEditAllOrganisersEventRoundtablesBulkIndexPath: ({
    slug = '',
    filterParams = new URLSearchParams(),
  }: {
    slug?: string;
    filterParams?: URLSearchParams;
  }) => ({
    path: `${BASE}/roundtables/bulk_edit_all`,
    as: interpolate(`${BASE}/roundtables/bulk_edit_all?` + filterParams, slug),
  }),
  organiserBillingPath: () => ({
    path: '/organiser-billing',
    as: '/organiser-billing',
  }),
};

export const reactRouterRoutes: RecursiveRouteObjectStrictId[] = [
  {
    id: 'root with not found error' as const,
    path: '/',
    element: <Root />,
    children: [],
    errorElement: <ErrorPage />,
  },
  {
    id: 'root' as const,
    path: BASE,
    element: <Root />,
    children: [],
  },
  {
    id: 'root_full_width' as const,
    path: BASE,
    element: <Root fullWidthLayout />,
    children: [
      {
        id: 'onsite_floor_plan' as const,
        path: Routes.organisersEventFloorPlanPath('').path,
        lazy: () => import('./pages/onsite-floor'),
        ErrorBoundary,
      },
      {
        id: 'onsite_checkin_area' as const,
        path: Routes.organisersEventCheckInAreaPath('').path,
        lazy: () => import('./pages/onsite-checkin'),
        ErrorBoundary,
      },
      {
        id: 'venue_schedules' as const,
        path: Routes.organisersEventSchedulesPath('').path,
        lazy: () => import('./pages/schedules'),
        ErrorBoundary,
      },
      {
        id: 'venue_stages' as const,
        path: Routes.organisersEventBackstagesPath('').path,
        lazy: () => import('./pages/stages'),
        restrictWithIstio: true,
        ErrorBoundary,
      },
      {
        id: 'venue_stages_new' as const,
        path: Routes.newOrganisersEventBackstagePath('').path,
        lazy: () => import('./pages/new-stage'),
        restrictWithIstio: true,
        ErrorBoundary,
      },
      {
        id: 'venue_roundtables' as const,
        path: Routes.organisersEventRoundtablesPath('').path,
        lazy: () => import('./pages/sessions'),
        ErrorBoundary,
      },
      {
        id: 'venue_roundtables_bulk_edit_group' as const,
        path: Routes.bulkEditOrganisersEventRoundtablesBulkIndexPath({
          slug: '',
          sessionIds: [],
        }).path,
        lazy: () => import('./pages/bulk-edit-sessions'),
        ErrorBoundary,
      },
      {
        id: 'venue_roundtables_bulk_edit_all' as const,
        path: Routes.bulkEditAllOrganisersEventRoundtablesBulkIndexPath({
          slug: '',
        }).path,
        lazy: () => import('./pages/bulk-edit-sessions'),
        ErrorBoundary,
      },
      {
        id: 'venue_roundtables_new' as const,
        path: Routes.newOrganisersEventTimetableRoundtablePath({
          slug: '',
          timetableId: undefined,
        }).path,
        lazy: () => import('./pages/new-session'),
        ErrorBoundary,
      },
      {
        id: 'venue_roundtables_edit' as const,
        path: Routes.editOrganisersEventTimetableRoundtablePath({
          slug: '',
          timetableId: undefined,
        }).path,
        lazy: () => import('./pages/edit-session'),
        ErrorBoundary,
      },
      {
        id: 'people_speakers' as const,
        path: Routes.organisersEventSpeakersPath('').path,
        lazy: () => import('./pages/speakers'),
        ErrorBoundary,
      },
      {
        id: 'custom_domains' as const,
        path: Routes.organisersEventCustomDomainsPath('').path,
        lazy: () => import('./pages/custom-domains'),
        ErrorBoundary,
      },
      {
        id: 'marketing' as const,
        path: Routes.organisersEventMarketingPath('').path,
        lazy: () => import('./pages/marketing'),
        restrictWithIstio: true,
        ErrorBoundary,
      },
      {
        id: 'marketing_utm_codes' as const,
        path: Routes.organisersEventUtmCodesPath('').path,
        lazy: () => import('./pages/utm-codes'),
        ErrorBoundary,
      },
      {
        id: 'setup_theme' as const,
        path: Routes.designOrganisersEventPath('').path,
        lazy: () => import('./pages/design'),
        ErrorBoundary,
      },
      {
        id: 'manage_recordings' as const,
        path: `${Routes.organisersEventRecordingsPath('').path}/:category?`,
        lazy: () => import('./pages/manage-recordings'),
        restrictWithIstio: true,
        ErrorBoundary,
        featureFlag: 'replay-enable-unified-recordings-model',
      },
      {
        id: 'manage_backup_recordings' as const,
        path: Routes.organisersEventRecordingsBackupPath('').path,
        lazy: () => import('./pages/manage-recordings/backup-recordings'),
        restrictWithIstio: true,
        ErrorBoundary,
        featureFlag: 'replay-enable-unified-recordings-model',
      },
      {
        id: 'manage_rehearsal_recordings' as const,
        path: Routes.organisersEventRecordingsRehearsalPath('').path,
        lazy: () => import('./pages/manage-recordings/rehearsal-recordings'),
        restrictWithIstio: true,
        ErrorBoundary,
        featureFlag: 'replay-enable-unified-recordings-model',
      },
      {
        id: 'view_recording' as const,
        path: `${Routes.organisersEventRecordingPath('').path}`,
        lazy: () => import('./pages/manage-recordings/view-recording'),
        restrictWithIstio: true,
        ErrorBoundary,
        featureFlag: 'replay-enable-unified-recordings-model',
      },
      {
        id: 'create_cuts' as const,
        path: `${Routes.organisersEventRecordingsCreateCutsPath('').path}`,
        lazy: () => import('./pages/manage-recordings/recording-library-slice'),
        restrictWithIstio: true,
        ErrorBoundary,
        featureFlag: 'replay-enable-unified-recordings-model',
      },
      {
        id: 'overview' as const,
        path: `${Routes.organisersEventOverviewPath('').path}`,
        lazy: () => import('./pages/overview'),
        ErrorBoundary,
      },
      {
        id: 'setup' as const,
        path: `${Routes.organisersEventSetupPath('').path}`,
        lazy: () => import('./pages/setup'),
        ErrorBoundary,
      },
      {
        id: 'registration_landing_page' as const,
        path: `${Routes.organiserRegistrationEventLandingPagePath('').path}`,
        lazy: () => import('./pages/registration-page'),
        ErrorBoundary,
      },
      {
        id: 'analytics_registrations_summary' as const,
        path: `${Routes.organisersEventRegistrationsSummaryPath('').path}`,
        lazy: () => import('./pages/analytics-registration'),
        restrictWithIstio: true,
        ErrorBoundary,
      },
      {
        id: 'engagement_summary' as const,
        path: `${Routes.organisersEventEngagementSummaryPath('').path}`,
        lazy: () => import('./pages/engagement-page'),
        restrictWithIstio: true,
        ErrorBoundary,
      },
      {
        id: 'emails' as const,
        path: Routes.organisersEmailsPath('').path,
        lazy: () => import('./pages/emails'),
        ErrorBoundary,
        restrictWithIstio: true,
      },
    ] as RouteObjectStrictId[],
  },
  {
    id: 'error' as const,
    path: '/error',
    lazy: () => import('./pages/error'),
    children: [],
  },
];

// Making routes ids safe and known
type RouteObjectStrictId = RouteObject & {
  id: string;
  featureFlag?: string;
  restrictWithIstio?: boolean;
};
type RecursiveRouteObjectStrictId = RouteObjectStrictId & {
  children?: RouteObjectStrictId[];
};
type RouteIDs<Routes extends RecursiveRouteObjectStrictId[]> =
  Routes[number]['children'] extends RecursiveRouteObjectStrictId[]
    ? Routes[number]['id'] | RouteIDs<Routes[number]['children']>
    : Routes[number]['id'];

export type AppRouteIDs = RouteIDs<typeof reactRouterRoutes>;

export const supportUrl = 'https://events-support.ringcentral.com/hc/en-us';
export const whatsNewUrl =
  'https://support.ringcentral.com/release-notes/mvp/video/events.html';
export const viewerLimitsSupportUrl =
  'https://events-support.ringcentral.com/hc/en-us/articles/360056528631-Max-On-Screen-and-Viewer-Limits';
export const brandingKnowledgeBaseUrl =
  'https://events-support.ringcentral.com/hc/en-us/sections/8367244524052-Design-Branding';
export const sessionMaxParticipantsSupportUrl =
  'https://events-support.ringcentral.com/hc/en-us/articles/360056528631-Max-On-Screen-and-Viewer-Limits';
export const turnoutTipsUrl =
  ' https://hopin.com/blog/13-tips-for-successful-virtual-events-from-team-hopin';
export const learnUrl =
  'https://university.ringcentral.com/page/en-rce-creating-successful-events';
