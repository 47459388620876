import { SVGProps } from 'react';

export const MoreHorizontal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      opacity="0.4"
      d="M4.02002 14C2.91602 14 2.01501 13.104 2.01501 12C2.01501 10.896 2.90501 10 4.01001 10H4.02002C5.12402 10 6.02002 10.896 6.02002 12C6.02002 13.104 5.12502 14 4.02002 14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M12.02 14C10.916 14 10.015 13.104 10.015 12C10.015 10.896 10.905 10 12.01 10H12.02C13.124 10 14.02 10.896 14.02 12C14.02 13.104 13.125 14 12.02 14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      opacity="0.4"
      d="M20.02 14C18.916 14 18.015 13.104 18.015 12C18.015 10.896 18.905 10 20.01 10H20.02C21.124 10 22.02 10.896 22.02 12C22.02 13.104 21.125 14 20.02 14Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
