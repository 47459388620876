import { Trans, useTranslation } from '@hopin-team/provider-translation';
import cx from 'classnames';
import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Pendo } from '@/components/pendo-tracking';
import UpgradeDialogLoader from '@/components/upgrade-dialog-loader';
import { useEventDomain } from '@/pages/custom-domains/hooks/useEventDomain';
import { FLAG_ENABLE_HELP_CENTER } from '@/utils/flags';

import { getButtonStyles } from '../components/button/button';
import { HelpCenter } from '../components/HelpCenter';
import { OpenInNew } from '../components/icons';
import { getPageTitle } from '../helpers/pageTitle';
import { useSetupZendesk } from '../hooks/useSetupZendesk';
import { useRootSelector } from '../store';
import {
  getFlags,
  getIsImpersonating,
  getOrganization,
  getUser,
} from '../store/selectors';
import { Header } from './components/Header';
import { Content } from './Content';
import { Nav } from './nav';
import { ToastContainer } from './nav/components/ToastContainer';

export const LinkButton = ({
  label,
  href,
  customDomainFor,
}: {
  label: string;
  href: string;
  customDomainFor?: 'reg' | 'app';
}) => {
  const baseLink = useEventDomain(customDomainFor);
  return (
    <a
      href={baseLink + href}
      target="_blank"
      rel="noreferrer"
      data-testid="link-button"
      className={cx(
        'flex items-center gap-4',
        getButtonStyles({
          pattern: 'outline',
          size: 'small',
          inline: true,
        }),
      )}
    >
      <OpenInNew className="h-5 w-5" />
      <span>{label}</span>
    </a>
  );
};

const TITLE_PATH_INDEX = 4;

type AppScaffoldingProps = {
  children: React.ReactNode;
  fullWidthLayout?: boolean;
};

export const AppScaffolding = ({
  children,
  fullWidthLayout,
}: AppScaffoldingProps) => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const user = useRootSelector(getUser);
  const organization = useRootSelector(getOrganization);
  const isImpersonating = useRootSelector(getIsImpersonating);
  const flags = useRootSelector(getFlags);
  useSetupZendesk();

  const helpCenterEnabled = Boolean(flags?.[FLAG_ENABLE_HELP_CENTER]);

  const pathKey = useMemo(() => {
    const currentPathSplit = pathname.split('/');
    return currentPathSplit.length === TITLE_PATH_INDEX + 1
      ? currentPathSplit[TITLE_PATH_INDEX]
      : currentPathSplit
          .slice(TITLE_PATH_INDEX)
          .map(str => {
            const numbersOnly = new RegExp(/^[0-9]*$/);
            return numbersOnly.test(str) ? 'id' : str;
          })
          .join('_');
  }, [pathname]);
  const pageTitle = useMemo(() => {
    return getPageTitle(t, pathKey);
  }, [pathKey, t]);

  useEffect(() => {
    document.title = `${pageTitle} | RingCentral Events`;
  }, [pageTitle]);

  if (!flags) {
    return null;
  }

  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <ToastContainer />

      <Nav />

      {isImpersonating && !!user?.firstName && (
        <div className="font-inter fixed z-50 flex w-full items-center justify-center gap-1 bg-[#DDCC00] py-[1px] text-center text-sm">
          <span className="font-medium">{t('impersonation.warning')}</span>

          <Trans
            i18nKey="impersonation.name-warning"
            t={t}
            values={{ name: user?.firstName + ' ' + user?.lastName }}
            components={{ bold: <span key="bold" className="font-medium" /> }}
          />
        </div>
      )}

      <main className="grid h-full flex-auto auto-rows-[auto_1fr] overflow-auto bg-white">
        <div role="banner" className="sticky top-0 z-10">
          <Header title={pageTitle || ''} fullWidthLayout={fullWidthLayout}>
            <div className="inline-flex gap-2">
              <LinkButton
                customDomainFor="reg"
                href={`/events/${slug}`}
                label={t('nav.preview-registration')}
              />

              <LinkButton
                customDomainFor="app"
                href={`/organisers/events/${slug}/preview`}
                label={t('nav.preview-event')}
              />
            </div>
          </Header>
        </div>

        <Content restrictedWidth={!fullWidthLayout}>{children}</Content>
        {helpCenterEnabled && <HelpCenter />}

        <Pendo user={user} organization={organization} />
        <UpgradeDialogLoader />
      </main>
    </div>
  );
};
