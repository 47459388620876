import { SVGProps } from 'react';

export const UsersGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      opacity="0.4"
      d="M13.785 13.389H10.2159C6.55094 13.389 5.25098 16.061 5.25098 18.349C5.25098 20.381 6.38196 21.499 8.43396 21.499H15.568C17.62 21.499 18.751 20.38 18.751 18.349C18.75 16.062 17.45 13.389 13.785 13.389Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M12.0081 11.512C13.9501 11.512 15.5291 9.93898 15.5291 8.00598C15.5291 6.07198 13.9491 4.5 12.0081 4.5C10.0671 4.5 8.48804 6.07298 8.48804 8.00598C8.48804 9.93898 10.0661 11.512 12.0081 11.512Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      opacity="0.4"
      d="M22.5 12.65C22.5 14.21 21.6399 15.0699 20.0699 15.0699H19.65C19.52 15.0699 19.4199 15 19.3699 14.89C18.6399 13.57 17.41 12.53 15.64 12.11C15.41 12.05 15.3099 11.77 15.4799 11.61C16.1699 10.96 16.67 10.11 16.89 9.16003C16.92 9.03003 17.04 8.93005 17.18 8.93005H18.79C21.53 8.93005 22.5 10.94 22.5 12.65Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M20.1801 5.17004C20.1801 6.65004 18.98 7.84998 17.51 7.84998C17.436 7.84998 17.363 7.84597 17.292 7.83997C17.2 7.83197 17.1321 7.80205 17.0861 7.75305C17.0401 7.70405 17.0141 7.65705 17.0081 7.58105C16.9011 6.23005 16.2481 5.03496 15.2691 4.20996C15.2081 4.15796 15.178 4.12301 15.163 4.05701C15.149 3.99401 15.147 3.92198 15.184 3.85498C15.642 3.04598 16.512 2.49902 17.51 2.49902C18.98 2.50002 20.1801 3.70004 20.1801 5.17004Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      opacity="0.4"
      d="M1.5 12.65C1.5 14.21 2.36005 15.0699 3.93005 15.0699H4.34998C4.47998 15.0699 4.58 15 4.63 14.89C5.36 13.57 6.58999 12.53 8.35999 12.11C8.58999 12.05 8.69002 11.77 8.52002 11.61C7.83002 10.96 7.32999 10.11 7.10999 9.16003C7.07999 9.03003 6.95995 8.93005 6.81995 8.93005H5.20996C2.46996 8.93005 1.5 10.94 1.5 12.65Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M3.81995 5.17004C3.81995 6.65004 5.01999 7.84998 6.48999 7.84998C6.56399 7.84998 6.63701 7.84597 6.70801 7.83997C6.80001 7.83197 6.86794 7.80205 6.91394 7.75305C6.95994 7.70405 6.98594 7.65705 6.99194 7.58105C7.09894 6.23005 7.75196 5.03496 8.73096 4.20996C8.79196 4.15796 8.82204 4.12301 8.83704 4.05701C8.85104 3.99401 8.85304 3.92198 8.81604 3.85498C8.35804 3.04598 7.48799 2.49902 6.48999 2.49902C5.01999 2.50002 3.81995 3.70004 3.81995 5.17004Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
